import {
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { useStyles } from "./styles";
import { Select, TextInput } from "qdm-component-library";
import { getDateAndTime, UIColor, utcTOLocal } from "../../utils";
import AddNewBillingOrder from "./addNewBillingOrder";
import TableComponent from "./tableComponent";
import { billingHeader, calculationHeader } from "./dummyData";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BillingSettlement = (props) => {
  const classes = useStyles();
  const styles = {
    labelStyle: {
      color: "#6F6F6F",
      fontSize: "12px",
      marginBottom: "6px",
      fontFamily: `pc_regular !important`,
    },
    borderStyle: {
      borderRadius: 8,
      borderColor: "#E0E0E0",
    },
    inputStyle: {
      padding: 5,
      marginBottom: 10,
      fontSize: "12px",
    },
  };

  const dataType = [
    { name: "no", type: "INCREMENT", header: "S.No" },
    { name: "service", type: "TEXT", header: "static" },
    { name: "description", type: "TEXT", header: "static" },
    { name: "orderRef", type: "TEXT", header: "static" },
    { name: "date", type: "TEXT", header: "static" },
    { name: "qty", type: "TEXT", header: "static" },
    { name: "uom", type: "TEXT", header: "static" },
    { name: "rate", type: "TEXT", header: "static" },
  ];

  const calculationDataType = [
    { name: "gross", type: "TEXT", header: "scroll" },
    { name: "dicount", type: "TEXTFIELD", header: "scroll" },
    { name: "net", type: "TEXT", header: "scroll" },
    { name: "patientShare", type: "TEXTFIELD", header: "scroll" },
    { name: "creditShare", type: "TEXT", header: "scroll" },
    { name: "action", type: "DELETE", header: "scroll" },
  ];

  const [newOrderSelected, setNewOrderSelected] = useState({
    chargeCode: {},
    uom: {},
    qty: "",
  });

  const [deductedAmnt, setDeductedAmnt] = useState({
    discountAmnt: 0,
    patientShare: 100,
  });

  const handlePayer = (e) => {
    props.handlePayer(e);
  };

  const handleAmountData = (type, value) => {
    const expression = /^[0-9.]*$/;
    const regex = new RegExp(expression);
    if (!value.match(regex)) {
      return false;
    }
    let checkArry = []
    checkArry.push(value.split("."))
    const checkedDecimal = checkArry?.[0]?.[1]?.length > 2 ? false : true;
    if (value < 100.01 && value >= 0 && value !== "-" && checkedDecimal) {
      setDeductedAmnt({ ...deductedAmnt, [type]: value });
      props.setSharePercent({ ...props?.sharePercent, [type]: value });
      let discountedShare = undefined;
      let patientValue = undefined;
      if (type === "discountAmnt") {
        if (value === "") {
          discountedShare = parseFloat(0);
        } else {
          discountedShare = parseFloat(value);
        }
      }
      if (type === "patientShare") {
        if (value === "") {
          patientValue = parseFloat(0);
        } else {
          patientValue = parseFloat(value);
        }
      }
      generateBilling(discountedShare, patientValue);
    }
  };

  const payerDropDown = useSelector(
    (state) => state?.billSettlementSlice?.payer_dropdown
  );
  const paymentDropDown = useSelector(
    (state) => state?.billSettlementSlice?.payment_dropDown
  );
  const roundOffValue = useSelector(
    (state) => state?.billSettlementSlice?.bill_roundoff
  );
  console.log("bill_roundoff", roundOffValue);
  React.useEffect(() => {
    generateBilling();
  }, []);

  // Final Total Calculation
  React.useEffect(() => {
    let gross = 0;
    let dicount = 0;
    let net = 0;
    let patientShare = 0;
    let creditShare = 0;
    const finalCalculation = props.selectedOrders.forEach((val, i) => {
      if (val?.isEdited) {
        const calculation = getEditedCalculation(val);
        props.handleCalculations(calculation, i);
        return console.log("what should i do");
      } else {
        if (val?.gross) {
          return (
            (gross += Number(val?.gross)),
            (dicount += Number(val?.dicount)),
            (net += Number(val?.net)),
            (patientShare += Number(val?.patientShare)),
            (creditShare += Number(val?.creditShare))
          );
        } else {
          return generateBilling();
        }
      }
    });
    const result = [
      gross.toFixed(2),
      dicount.toFixed(2) === "NaN" ? "0.00" : dicount.toFixed(2),
      net.toFixed(2) === "NaN" ? "0.00" : net.toFixed(2),
      patientShare.toFixed(2),
      creditShare.toFixed(2) === "NaN" ? "0.00" : creditShare.toFixed(2),
    ];
    props.setCalculationResultData({
      ...props?.calculationResultData,
      calculationResult: result,
      gross: gross,
      dicount: dicount,
      net: net,
      patientShare: patientShare,
      creditShare: creditShare,
    });
    props.setPaymentDetails({
      ...props?.paymentDetails,
      settleNowValue: patientShare,
    });
    getRoundOff(patientShare, props?.roundOffval);
  }, [props?.selectedOrders]);

  // Billing Table on the Right
  const generateBilling = (discountValue, patientValue) => {
    const newOrder = props?.selectedOrders;
    const calculation = newOrder?.map((val, i) => {
      if (typeof val === "object" && !Array.isArray(val) && val !== null) {
        const discountss =
          discountValue !== undefined
            ? discountValue
            : deductedAmnt?.discountAmnt;
        const patientPercent =
          patientValue !== undefined
            ? patientValue
            : deductedAmnt?.patientShare;
        const gross = (val?.qty * val?.rate).toFixed(2);
        const discount = (gross * discountss) / 100;
        const net = (gross - discount).toFixed(2);
        const patientShare = patientPercent;
        const patientPercentShare = (net * patientShare) / 100;
        const append = {
          gross: gross,
          dicount: discount,
          net: net,
          patientShare: (net * patientShare) / 100,
          creditShare: (net - patientPercentShare).toFixed(2),
          action: "delete",
        };
        return { ...val, ...append };
      } else {
        return val;
      }
    });
    props.handleCalculations(calculation, null);
  };

  // On edit Calculation change
  const getEditedCalculation = (val) => {
    if (typeof val === "object" && !Array.isArray(val) && val !== null) {
      const net = val?.gross - val?.dicount;
      const patientShare = val?.patientShare;
      const append = {
        net: Number(net.toFixed(2)),
        patientShare: patientShare,
        creditShare: Number((net - patientShare).toFixed(2)),
        action: "delete",
        isEdited: false,
      };
      return { ...val, ...append };
    } else {
      return val;
    }
  };

  const handleChargeCode = (e, type) => {
    if (type === "qty" && e <= 0 && e !== "") {
      return false;
    }
    setNewOrderSelected({
      ...newOrderSelected,
      [type]: e,
    });
  };

  const getRoundOff = (patientShare, roundOff) => {
    debugger;
    const initialSet = patientShare ? patientShare.toFixed(2) : patientShare;
    const patientDecimal = initialSet.toString().split(".")[1];
    const level = patientDecimal
      ? (Number("." + patientDecimal[0]) + Number(roundOff)).toFixed(2)
      : Number(roundOff);
    const levelCheck = level * 100;
    const diff = () => {
      const differenceVal = levelCheck - patientDecimal;
      if (patientDecimal < levelCheck) {
        if (differenceVal >= 3) {
          return {
            patientShareFinal: Number(level) - Number(roundOff),
            roundOff: (
              Number("." + patientDecimal[0]) - Number("." + patientDecimal)
            ).toFixed(2),
          };
        } else {
          return {
            patientShareFinal: level,
            roundOff: (Number(level) - Number("." + patientDecimal)).toFixed(2),
          };
        }
      } else {
        if (differenceVal <= -3) {
          return {
            patientShareFinal: Number(level) + Number(roundOff),
            roundOff: (
              Number(level) +
              Number(roundOff) -
              Number("." + patientDecimal)
            ).toFixed(2),
          };
        } else {
          return {
            patientShareFinal: level,
            roundOff: (Number(level) - Number("." + patientDecimal)).toFixed(2),
          };
        }
      }
    };
    props.setRoundOffAmnt(diff);
  };
  console.log("props?.roundOffAmnt?.roundOff", props?.roundOffAmnt?.roundOff);
  return (
    <div>
      {props.addNewSwitch && (
        <AddNewBillingOrder
          handleRemoveAddnew={props.handleRemoveAddnew}
          handleAddNewOrder={props.handleAddNewOrder}
          handleChargeCode={handleChargeCode}
          newOrderSelected={newOrderSelected}
          setNewOrderSelected={setNewOrderSelected}
          deductedAmnt={deductedAmnt}
        />
      )}
      <Grid
        container
        spacing={2}
        style={{
          padding: "8px 20px 0px",
        }}
      >
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Select
                showArrow
                label="Insurance Payer"
                options={payerDropDown?.data ?? []}
                value={props?.payer?.value ?? ""}
                onChangeValue={(e) => handlePayer(e)}
                placeholder="select..."
                labelStyle={styles.labelStyle}
                inLineStyles={styles.borderStyle}
                hoverColor={UIColor.primaryColor.color}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{ paddingRight: "4px", paddingLeft: "4px" }}
            >
              <TextInput
                className={"textInput"}
                id={"patient-share"}
                label={"Patient Share%"}
                // type="number"
                placeholder=""
                labelStyle={styles.labelStyle}
                disabled={props?.payer?.value ? false : true}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={deductedAmnt?.patientShare}
                onChange={(e) =>
                  handleAmountData("patientShare", e.target.value)
                }
                hoverColor={UIColor.lineBorderFill.color}
              />
            </Grid>
            <Grid item xs={2}>
              <TextInput
                className={"textInput"}
                id={"discount"}
                label={"Discount%"}
                // type="number"
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={deductedAmnt?.discountAmnt}
                onChange={(e) =>
                  handleAmountData("discountAmnt", e.target.value)
                }
                hoverColor={UIColor.lineBorderFill.color}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"bill"}
                label={"Bill No"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={props?.billNo?.billNum ?? ""}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"date"}
                label={"Date"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={props?.billNo?.date}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"receipt"}
                label={"Receipt No"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={props?.receipt?.receiptNo}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>
            <Grid item xs={3}>
              <TextInput
                className={"textInput"}
                id={"reciept_date"}
                label={"Date"}
                placeholder=""
                labelStyle={styles.labelStyle}
                style={{ ...styles.borderStyle, ...styles.inputStyle }}
                value={props?.receipt?.date}
                hoverColor={UIColor.lineBorderFill.color}
                disabled={true}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/*-------------------------------------- Table component -------------------------------------------------- */}
      <Grid container>
        <Grid item xs={6} style={{ borderRight: "1px solid #DFE5EB" }}>
          <TableComponent
            dataType={dataType}
            tableData={props?.selectedOrders}
            Header={billingHeader}
            tableWidth={"1050px"}
            customRow={true}
          />
        </Grid>
        <Grid item xs={6}>
          <TableComponent
            dataType={calculationDataType}
            tableData={props?.selectedOrders}
            Header={calculationHeader}
            totalCalculationRow={true}
            calculationResultData={
              props?.calculationResultData.calculationResult
            }
            handleDeleteSelected={props.handleDeleteSelected}
            handleTableTextChange={props.handleTableTextChange}
            payerValue={props?.payer?.value}
          />
        </Grid>
      </Grid>
      {/*---------------------------------------------------------------------------------------- */}

      {/* =========================================Final Calculated Data================================================= */}
      <Grid
        container
        style={{
          padding: "6px 20px",
          backgroundColor: "#F1F6FC",
        }}
      >
        <Grid item xs={12}>
          <div
            className={classes.alignItem}
            style={{
              gap: "10px",
              justifyContent:
                props?.paymentDetails?.paymentType?.value === "Cheque"
                  ? "space-evenly"
                  : "end",
            }}
          >
            <Typography
              className={classes.calculationLabel}
              style={{ borderRight: "1px solid #DEE5EC", paddingRight: "10px" }}
            >
              Bill Rounding Amt :{" "}
              <span className={classes.claculationSpan}>
                {props?.roundOffAmnt?.roundOff.toString() === "NaN"
                  ? 0.00
                  : props?.roundOffAmnt?.roundOff}
              </span>
            </Typography>
            <Typography
              className={classes.calculationLabel}
              style={{ borderRight: "1px solid #DEE5EC", paddingRight: "10px" }}
            >
              Patient Share:{" "}
              <span className={classes.claculationSpan}>
                {(
                  props?.calculationResultData?.patientShare +
                  Number(props?.roundOffAmnt?.roundOff)
                ).toFixed(2) === "NaN"
                  ? 0.00
                  : (
                      props?.calculationResultData?.patientShare +
                      Number(props?.roundOffAmnt?.roundOff)
                    ).toFixed(2)}
              </span>
            </Typography>
            <FormControlLabel
              style={{ marginRight: "0px" }}
              control={
                <Checkbox
                  checked={props?.paymentDetails?.settleNow}
                  color="primary"
                  size="small"
                  onChange={(e) =>
                    props?.handlePayementDetails(e.target.checked, "settleNow")
                  }
                />
              }
              label={
                <Typography className={classes.calculationLabel}>
                  Settle Now
                </Typography>
              }
            />
            <TextField
              id="standard-full-width"
              style={{ width: "6%", fontSize: "12px" }}
              className={classes.calculationTextField}
              fullWidth
              margin="normal"
              value={(
                Number(props?.paymentDetails?.settleNowValue) +
                Number(props?.roundOffAmnt?.roundOff)
              ).toFixed(2) === "NaN" ? 0.00 : (
                Number(props?.paymentDetails?.settleNowValue) +
                Number(props?.roundOffAmnt?.roundOff)
              ).toFixed(2)}
              disabled={true}
              onChange={(e) =>
                props?.handlePayementDetails(e.target.value, "settleNowValue")
              }
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              size={"small"}
              variant={"outlined"}
            />
            <Typography className={classes.calculationLabel}>
              Balance Due :{" "}
              <span className={classes.claculationSpan}>
                {props?.paymentDetails?.balanceDue}
              </span>
            </Typography>
            {props?.paymentDetails?.settleNow && (
              <div style={{ width: "10%" }}>
                <Select
                  showArrow
                  options={paymentDropDown?.data ?? []}
                  onChangeValue={(e) =>
                    props?.handlePayementDetails(e, "paymentType")
                  }
                  value={props?.paymentDetails?.paymentType?.value}
                  placeholder="select..."
                  labelStyle={styles.labelStyle}
                  inLineStyles={{ ...styles.borderStyle }}
                  hoverColor={UIColor.primaryColor.color}
                />
              </div>
            )}
            {props?.paymentDetails?.paymentType?.value === "Cheque" && (
              <>
                <Typography className={classes.calculationLabel}>
                  Doc Ref No :
                </Typography>
                <TextField
                  id="standard-full-width"
                  className={classes.calculationTextField}
                  fullWidth
                  value={props?.paymentDetails?.docRefNo}
                  margin="normal"
                  onChange={(e) =>
                    props?.handlePayementDetails(e.target.value, "docRefNo")
                  }
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  size={"small"}
                  inputProps={{ maxLength: 20 }}
                  variant={"outlined"}
                />
                <Typography className={classes.calculationLabel}>
                  Doc Ref Date :
                </Typography>
                <div style={{ width: "10%" }}>
                  <DatePicker
                    // minDate={props?.minDate}
                    maxDate={new Date()}
                    selected={new Date()}
                    // selectsRange
                    // startDate={startDate}
                    // endDate={endDate}
                    onChange={(date) =>
                      props?.handlePayementDetails(date, "docRefDate")
                    }
                    isClearable={false}
                    customInput={
                      <div style={{ position: "relative" }}>
                        <TextField
                          id="standard-full-width"
                          className={classes.calculationTextField}
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              input: classes.resize,
                            },
                          }}
                          fullWidth
                          margin="normal"
                          value={getDateAndTime(
                            props?.paymentDetails?.docRefDate
                          )}
                          size={"small"}
                          variant={"outlined"}
                        />
                        <img
                          src="images/icons8-calendar-grey.svg"
                          style={{ position: "absolute", top: 19, right: 10 }}
                        />
                      </div>
                    }
                  />
                </div>
              </>
            )}
            {(props?.paymentDetails?.paymentType?.value === "Debit Card" ||
              props?.paymentDetails?.paymentType?.value === "Credit Card") && (
              <>
                <Typography className={classes.calculationLabel}>
                  Card No :
                </Typography>
                <TextField
                  id="standard-full-width"
                  InputProps={{
                    classes: {
                      input: classes.resize,
                    },
                  }}
                  className={classes.calculationTextField}
                  fullWidth
                  type={"number"}
                  value={props?.paymentDetails?.card}
                  margin="normal"
                  onChange={(e) =>
                    props?.handlePayementDetails(e.target.value, "card")
                  }
                  size={"small"}
                  inputProps={{ maxLength: 20 }}
                  variant={"outlined"}
                />
                <Typography className={classes.calculationLabel}>
                  Expiry Date :
                </Typography>
                <div style={{ width: "10%" }}>
                  <DatePicker
                    minDate={new Date()}
                    selected={
                      props?.paymentDetails?.expiry
                        ? props?.paymentDetails?.expiry * 1000
                        : new Date()
                    }
                    showYearDropdown
                    showMonthDropdown
                    dropdownMode="select"
                    onChange={(date) =>
                      props?.handlePayementDetails(date, "expiry")
                    }
                    isClearable={false}
                    customInput={
                      <div style={{ position: "relative" }}>
                        <TextField
                          id="standard-full-width"
                          className={classes.calculationTextField}
                          style={{ width: "100%" }}
                          InputProps={{
                            classes: {
                              input: classes.resize,
                            },
                          }}
                          fullWidth
                          margin="normal"
                          value={getDateAndTime(
                            props?.paymentDetails?.expiry,
                            true,
                            true
                          )}
                          size={"small"}
                          variant={"outlined"}
                        />
                        <img
                          src="images/icons8-calendar-grey.svg"
                          style={{ position: "absolute", top: 15, right: 10 }}
                        />
                      </div>
                    }
                  />
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      {/* ==================================================================================================================== */}
    </div>
  );
};

export default BillingSettlement;
