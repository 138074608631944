import React from "react";
import { actions } from "primarycare-binder";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	Header,
	ReasonforAppointment,
	DoctorCardDetail,
	AppointmentInstructions,
	BillSummary,
	DoctorAndAppointmentSlot,
	ReportAndAdditionalServices,
	FilterIcon,
	PatientListView,
	PatientCareTypeCard,
	ReferralListView,
	LoadingComp
} from "../../components";
import {
	Container,
	Row,
	Col,
	Link,
	Div,
	Button,
	SideMenu,
	TextInput,
	Text,
	Divider,
	TapSelect,
	MobileNumberWithCode,
	TermsAndConditions,
	OtpInput,
	H6,
	Overlay,
	Avatar,
	Popover,
} from "qdm-component-library";
import Popup from "@material-ui/core/Popover";
import { DoctorConsultation } from "..";
import Sidemenu1 from "./sidemenu1";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import { Card, Grid } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Reasonforappointment from "../../assets/img/reason_for_appointment.svg";
import Modify_appointment from "../../assets/img/modify_appointment.svg";
import {
	AlertProps,
	checkError,
	getImgUrl,
	getMobileNumber,
	getUserInfo,
	mobileNumberValidation,
	UIColor,
	upsertPerson,
	utcTOLocal,
	ValidateEmail,
} from "../../utils";
import { Calendar } from "../../assets/img/calendar.js";
import FilterDrawer from "./filterDrawer";
import { Routes } from "../../router/routes";
import { AlertContext } from "../../contexts";
import "react-calendar/dist/Calendar.css";
import Calendars from "react-calendar";
import moment from "moment";
import { filterListingData, uploadImage } from "../../utils";
import { withAllContexts } from "../../HOCs";
import { checkWithCasbin } from "../../utils/permissonHandling"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDatePicker from "../../components/customDatePicker";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const casbinjs = require("casbin.js");

const styles = (theme) => ({
	expand: {
		transform: "rotate(0deg)",
		padding: 16,
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	body: {
		paddingTop: 0,
		paddingLeft: 16,
		paddingRight: 16,
		paddingBottom: 16,
	},
	label: {
		"& span": {
			fontSize: 12,
		},
		marginBottom: "16px",
	},
	guestTitle: {
		fontSize: 20,
		fontFamily: "pc_semibold",
		letterSpacing: 0,
		lineHeight: 1,
	},
	termsAndConditions: {
		"& .termsConditionsCheckbox": {
			borderRadius: 2,
		},
		"& .termsConditionsLabel": {
			flex: 1,
		},
	},
	monthCalendar: {
		"& strong": {
			fontSize: 13,
		},
		"& span": {
			fontSize: 13,
		},
		backgroundColor: "#fff",
	},
	clear: {
		backgroundColor: "transparent",
		border: "none",
		padding: "9px 12px",
		cursor: "pointer",
		color: "#6F6F6F",
		fontSize: 14,
	},
	apply: {
		backgroundColor: "transparent",
		border: "none",
		padding: "9px 12px",
		cursor: "pointer",
		color: "#0071F2",
		fontSize: 14,
	},
	divider: {
		minWidth: "0px",
		marginRight: "10px",
		marginLeft: "10px",
		color: "#B6B6B6",
		marginBottom: 0,
		marginTop: 0,
	},
	earliestInput: {
		fontFamily: "pc_regular",
		fontSize: 14,
		"&::placeholder": {
			fontFamily: "pc_regular",
			fontSize: 14,
			color: "#b6b6b6",
		},
	},
	textField: {
		"& input": {
			color: "#101010",
			fontSize: "14px",
			border: "1px solid #E0E0E0",
			fontFamily: "pc_medium",
			borderRadius: 8,
		},
		"& input::placeholder": {
			color: "#B6B6B6",
		},
	},
	tapSelect: {
		"& .selectionDiv": {
			height: 40,
			borderRadius: 8,
			minWidth: 40,
			margin: "8px 8px 0px 0px",
			borderColor: "#E0E0E0",
			"&:last-of-type": {
				marginRight: 0,
			},
			"& span": {
				fontFamily: "pc_regular",
				fontSize: 14,
			},
		},
		"& .active": {
			"& span": {
				fontFamily: "pc_medium !important",
			},
		},
	},
	mobile: {
		marginBottom: 16,
		"& .callingCodes,input": {
			fontSize: 14,
			color: "#101010",
			fontFamily: "pc_medium",
			letterSpacing: "-0.34px",
			fontWeight: "normal",
		},
		"& input::placeholder": {
			fontFamily: "pc_regular",
		},
		// '& #addmember_form_mobilenumberwithcode':{
		//   borderColor:'#E0E0E0'
		// },
		"& .mobileCountryDivider": {
			borderColor: "#E0E0E0",
		},
	},
});
class DoctorListing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading:true,
			callcen: false,
			Persondata: [],
			fin_data: [],
			fromListing: false,
			referralData: [],
			selectOption: {},
			oldSlot: {
				isUpdate: false,
				info: {},
				appInfoFetched: {},
			},
			sidebar: false,
			collpaseOpen: 0,
			showOtpVerification: false,
			bulkBooking: false,
			city: this.props?.location?.state?.cityName || "",
			loaction: this.props?.location?.state?.loaction || "",
			hospital: this.props?.location?.state?.hospital || [],
			specialities: this.props?.location?.state?.specialities || [],
			symptoms: this.props?.location?.state?.symptoms || [],
			doctors: this.props?.location?.state?.doctors || [],
			symptomsSearch: this.props?.location?.state?.symptomsSearch || [],
			data: [],
			meageget: {},
			Tempdata: [],
			profileDetails: [],
			guestInfo: {
				name: this.props?.guestInfo?.data?.name || "",
				age: this.props?.guestInfo?.data?.age || "",
				gender: this.props?.guestInfo?.data?.gender || "",
				mobileNo: {},
				mailId: "",
			},
			guestBookerInfo: {
				name:
					this.props?.guestInfo?.data?.type === 0
						? this.props?.guestInfo?.data?.name || ""
						: "",
				relationship:
					this.props?.guestInfo?.data?.type === 0
						? { value: "Others", label: "Others" }
						: {},
				mobileNo: {},
				mailId: "",
			},
			otp: 0,
			addBookBy: true,
			selectedSlot: {},
			selectedSlotId: null,
			selectedSlotOrd: null,
			appointmentReason: [],
			modifyAppointmentReason: {},
			doctorInfo: "",
			indexToShow: null,
			age: {
				val: moment().format("DD-MM-YYYY"),
				milli: moment().valueOf(),
			},
			allLang: [],
			rightSideAllCard: false,
			iagree: false,
			sideInfo: {
				age: 0,
				gender: "",
			},
			openCloseCalendar: false,
			filter: false,
			resetTimeSlot: null,
			consultationMode: {
				value: "Direct Consultation",
				label: "Direct Consultation",
			},
			monthCalendarInputValue: "",
			error: {},
			appointmentUpdate: !!this.props?.location?.state?.modifyUser,
			isMultipleAppointment: false,
			multiApppointmentInfo: {
				appointments: {
					startsOn: {
						date: "",
						slotSelect: {},
						milli: 0,
					},
					preferrence: {
						preferredDays: [],
						preferredWeeks: [],
					},
					occurence: {
						occurrenceHandle: "NoOfDays",
						occurrenceValue: 1,
					},
					reccurance: {
						reccuranceValue: 1,
						reccuranceType: { label: "Days", value: "days" },
					},
				},
				info: {},
				data: [],
				slotObj: {},
				filterData: {},
				uploadFiles: [],
			},
			selectedDate: {
				unix: moment().startOf("date").unix(),
				val: moment().startOf("date").format("DD-MM-YYYY"),
			},
			permissionDa:[],
			permissionData:[],
			uploadFiles: [],
			patientInstruction: "",
			modalOpen: false,
			doctorName: "",
			doctorCount: 0
		};
		this.overlayNode = React.createRef();
	}

	handleModalOpen = async () => {
		debugger
		let personId_ = this.props?.location?.state?.selectedUser?.userId;
		if (!personId_) {
			let mobileNo = this.props?.location?.state?.selectedUser?.mobile;
			const persondata = await this.props.READ_PERSON({
				phone: mobileNo,
			});
			personId_ = persondata?.payload?.data?.id;
		}
		let start_ = this.state.selectedSlot?.info?.dateStart ?? 0;
		let res = await this.props.CHECK_APPOINTMENT_AGAINST_PATIENT({ patientId: personId_, start: start_ })
		if(res?.payload?.data?.Code === 201){
		  if(Array.isArray(res?.payload?.data?.result) && res?.payload?.data?.result?.length > 0){
			if(res?.payload?.data?.result?.[0]?.allowToBookAppointment === false && res?.payload?.data?.result?.[0]?.doctorName?.length > 0){
			  this.setState({
				...this.state,
				modalOpen: true,
				doctorName: res?.payload?.data?.result?.[0]?.doctorName ?? "",
				doctorCount: res?.payload?.data?.result?.length 
			  })
			}
		  } else {
			this.handleBooking();
		  }
		} else {
		  this.context.setSnack({
			open: true,
			severity: AlertProps.severity.error,
			msg: "Something went wrong",
			vertical: AlertProps.vertical.top,
			horizontal: AlertProps.horizontal.right,
			tone: true,
		  });
		}
		debugger
	};
	
	handleModalClose = () => {
		this.setState({
		  ...this.state,
		  modalOpen: false
		})
	};

	handleMultiAppointmentChange = (val, key1, key2, key3, key4) => {
		const state = { ...this.state };
		if(key4){
			if(key4 === "deleteAllDays"){
				state["multiApppointmentInfo"][key1][key2][key3] = [];
			}
		}
		if (key2) {
			if (key3) {
				state["multiApppointmentInfo"][key1][key2][key3] = val;
			} else {
				state["multiApppointmentInfo"][key1][key2] = val;
			}
		} else {
			state["multiApppointmentInfo"][key1] = val;
			if (key1 === "info") {
				state["multiApppointmentInfo"]["slotObj"] = {};
			}
		}

		this.setState({
			...state,
		});
	};

	updateMultiSlots = (val) => {
		this.setState({
			...this.state,
			fromListing: false,
			isMultipleAppointment: val,
		});
	};

	getPathName = (dateStart) => {
		const walkInCondition = (
			moment().startOf("day").diff(moment(dateStart * 1000), "d") === 0 &&
			this.state.selectedSlot?.info?.maxwalkin > 0
		);
		let isWalkin = this.state?.permissionData?.write?.indexOf("walkIn") > -1;
		let returnPath = !walkInCondition
			? Routes.confirmBooking
			: (
				isWalkin
					? Routes.patientVisitDetails
					: Routes.confirmBooking
			);
		if (returnPath === Routes.patientVisitDetails) {
			if (!this.props.location.state?.patientId) {
				returnPath = Routes.confirmBooking;
			}
		}
		return returnPath;
	};

	handleBooking = async () => {
		this.handleModalClose();
		// this.setState({
		//   ...this.state,
		//   showOtpVerification: true,
		//   sidebar: true,
		//   otpNumber: this.props.location?.state?.unregister?.mobileNo?.mobileNo,
		// });
		if (this.props?.location?.state?.modifyUser && (!this.props?.location?.state?.newBook)) {
			if (!this.state.modifyAppointmentReason?.value) {
				this.context.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please select reason for modification",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.center,
					tone: true,
				});
				return;
			}
		}
    this.changeState("meageget", {});
	if (
		(!this.props?.location?.state?.modifyUser && this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1 && !this.props.location?.state?.unregister?.is_Unregister) ||
		(this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1 && this.props?.location?.state?.newBook)
	) {
		let newMNum = this.props.location?.state?.userNo;
		if (this.props?.location?.state?.modifyUser && this.props?.location?.state?.newBook) {
			const appointmentData = await this.props.APPOINTMENT_READ({
				appointmentId: this.props.location?.state?.appointMentInfo?.id,
			});
			const appointMentInfo = JSON.parse(
				JSON.stringify(appointmentData?.payload?.data)
			);
			this.changeState("oldSlot", {
				...this.state.oldSlot,
				appInfoFetched: appointMentInfo,
			});
			newMNum = appointMentInfo?.AppointmentNotification?.[0]?.mobile;
		}
		this.changeScreen();
		// await this.props.GENERATE_OTP({
		// 	mobileNo: newMNum,
		// });
		// this.setState({
		// 	...this.state,
		// 	showOtpVerification: true,
		// 	sidebar: true,
		// 	otpNumber: newMNum,
		// });
		return;
	} 
	// if(this.props.location?.state?.unregister?.is_Unregister){
	// 	let mobileNo = getMobileNumber(
	// 		this.props.location?.state?.unregister?.mobileNo
	// 	)
	// 	await this.props.GENERATE_OTP({
	// 		mobileNo: getMobileNumber(
	// 			this.props.location?.state?.unregister?.mobileNo
	// 		)
	// 	});
	// 	debugger;
	// 	this.setState({
	// 		...this.state,
	// 		showOtpVerification: true,
	// 		sidebar: true,
	// 		otpNumber: getMobileNumber(
	// 			this.props.location?.state?.unregister?.mobileNo
	// 		)
	// 	});
	// 	return;
	// } 
		if (this.props.location?.state?.unregister?.is_Unregister) {
			//alert(JSON.stringify(this.props.location?.state));
			await this.props.REGISTER_USERS({
				mobileNo: getMobileNumber(
					this.props.location?.state?.unregister?.mobileNo
				),
				firstName: this.props.location?.state?.unregister?.patientName,
				lastName: "",
				emailId: this.props.location?.state?.unregister?.emailId,
			});
			const { isError, errMsg } = checkError(this.props.registerUsers);
			if (isError) {
				this.context.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: errMsg,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.center,
					tone: true,
				});
			} else {
				this.changeScreen();
				// this.setState({
				// 	...this.state,
				// 	showOtpVerification: true,
				// 	sidebar: true,
				// 	otpNumber: this.props.location?.state?.unregister?.is_Unregister ? this.props.location?.state?.unregister?.mobileNo?.mobileNo : this.props.location?.state?.userNo,
				// });
			}
		}
        else {
			let UploadedFiles = [];
			let { uploadFiles } = this.state;
			UploadedFiles = await uploadImage(uploadFiles);
			if (this.state.selectedSlot?.info) {
				if (!!this.props?.location?.state?.modifyUser) {
					if (this.props?.location?.state?.newBook && !this.state.callcen) {
						const userInfo = getUserInfo();
						if (userInfo.type === "token") {
							if (this.state.iagree) {
								const {
									SpecialtyID = [],
									PersonID = [],
									practitionerID = [],
									AppointmentNotification,
									HealthcareServiceID,
								} = this.props?.location?.state?.appointMentInfo;
								const { info = {}, hospitalInfo = {} } =
									this.state.selectedSlot;
								const appointMentInput = {
									slotId: [info.value || 0],
									slotStart: info.dateStart || "",
									slotEnd: info.dateEnd || "",
									orgId: hospitalInfo?.org_Id,
									orgType: hospitalInfo?.orgType,
									resourcetype: info.resourcetype || "",
									resourcerole: info.resourcerole || "",
									resourcecode: info.resourcecode || "",
									info: this.state.appointmentReason,
									specialtyID: SpecialtyID,
									personID: PersonID,
									practitionerId: practitionerID,
									mobile: AppointmentNotification?.[0]?.mobile || "",
									email: AppointmentNotification?.[0]?.patientEmail || "",
									consultationMode: this.state.consultationMode.value,
									healthCareId: HealthcareServiceID,
									report_docs: UploadedFiles,
									patientInstruction: this.state.patientInstruction,
								};
								if (
									moment()
										.startOf("day")
										.diff(moment(info.dateStart * 1000), "d") === 0 &&
									this.state.selectedSlot?.info?.maxwalkin > 0
								) {
									appointMentInput["appointmentType"] = "walkin";
								}
								const appInfo = await this.props.APPOINTMENT_CREATE(
									appointMentInput
								);
								const { isError, errMsg } = checkError(appInfo.payload);
								if (isError) {
									this.context.setSnack({
										open: true,
										severity: AlertProps.severity.error,
										msg: errMsg,
										vertical: AlertProps.vertical.top,
										horizontal: AlertProps.horizontal.center,
										tone: true,
									});
								} else {

									if(this.props?.location?.state?.isFollowUp){
										await this.props.SET_FOLLOWUP_SERVICE({
											data: {
												status: "booked",
												editId:this.props?.location?.state?.followUpId
											},
											});
									}
									const appointmentId =
										appInfo.payload?.data?.appointment_response?.appointment
											?.Result?.[0]?.properties?.doc?.id || 0;
									this.setState({ ...this.state, sidebar: false }, () => {
										// this.props.history.push("/confirm_booking");
										this.props.visitData.setVisitDetails({
											encounter: {
												mode: this.state?.consultationMode?.value === "Direct Consultation" ? "direct" : this.state?.consultationMode?.value === "Video Consultation" ? "video" : "direct"
											},
											practitioner: {},
											device: {},
											helthcare: {},
											accompained: {},
										});
										this.props.history.push({
											pathname: this.getPathName(info.dateStart),
											state: {
												fromListing: true,
												personId: this.props.location?.state?.userId,
												mobileNo: this.props.location?.state?.mobileNo,
												appointmentId: appointmentId,
												patientId: this.props.location?.state?.patientId,
												from: (moment().startOf("day").diff(moment(info.dateStart * 1000), "d") === 0 && this.state.selectedSlot?.info?.maxwalkin > 0)?1:null,
												walkin: !(
													moment()
														.startOf("day")
														.diff(moment(info.dateStart * 1000), "d") === 0 &&
													this.state.selectedSlot?.info?.maxwalkin > 0
												),
											},
										});
									});
								}
							}
						}
						return;
					}
					const appointmentData = await this.props.APPOINTMENT_READ({
						appointmentId: this.props.location?.state?.appointMentInfo?.id,
					});
					const appointMentInfo = JSON.parse(
						JSON.stringify(appointmentData?.payload?.data)
					);
					this.changeState("oldSlot", {
						...this.state.oldSlot,
						appInfoFetched: appointMentInfo,
					});
					const status = checkError(appointmentData?.payload);
					if (status.isError) {
						this.context.setSnack({
							open: true,
							severity: AlertProps.severity.error,
							msg: status.errMsg,
							vertical: AlertProps.vertical.top,
							horizontal: AlertProps.horizontal.center,
							tone: true,
						});
					} else {
						this.changeScreen();
						// await this.props.GENERATE_OTP({
						// 	mobileNo: appointMentInfo?.AppointmentNotification?.[0]?.mobile,
						// });
						// const otpStatus = checkError(this.props.generateOTP);
						// if (!otpStatus.isError) {
						// 	this.setState({
						// 		...this.state,
						// 		collpaseOpen: null,
						// 		showOtpVerification: true,
						// 		sidebar: true,
						// 		otpNumber:
						// 			appointMentInfo?.AppointmentNotification?.[0]?.mobile,
						// 	});
						// } else {
						// 	this.context.setSnack({
						// 		open: true,
						// 		severity: AlertProps.severity.error,
						// 		msg: otpStatus.errMsg,
						// 		vertical: AlertProps.vertical.top,
						// 		horizontal: AlertProps.horizontal.center,
						// 		tone: true,
						// 	});
						// }
					}
					return;
				}
				const userInfo = getUserInfo();
				if (userInfo.type === "token") {
					if (this.state.iagree) {
						let personId = this.props?.location?.state?.selectedUser?.userId;
						if (!personId) {
							let mobileNo = this.props?.location?.state?.selectedUser?.mobile;
							const persondata = await this.props.READ_PERSON({
								phone: mobileNo,
							});
							personId = persondata?.payload?.data?.id;
						}
						const { info = {}, hospitalInfo = {} } = this.state.selectedSlot;
						if (this.state.isMultipleAppointment) {
							var appointMentInputArr = [];
							const promArr = this.state.multiApppointmentInfo.data.map(
								(singleSlot) => {
									appointMentInputArr.push({
										slotId: [singleSlot.id || 0],
										slotStart: singleSlot.start || "",
										slotEnd: singleSlot.end || "",
										...singleSlot.resourceInfo,
										orgId: hospitalInfo?.org_Id,
										orgType: hospitalInfo?.orgType,
										info: this.state.appointmentReason,
										specialtyID:
											this?.props?.location?.state?.specialities?.map(
												(spec) => spec?.value
											) || [],
										personID: [personId],
										practitionerId: hospitalInfo?.isClinic
											? []
											: [hospitalInfo?.id],
										mobile:
											this.props.location?.state?.selectedUser?.mobile || "",
										email:
											this.props.location?.state?.selectedUser?.email || "",
										consultationMode: this.state.consultationMode.value,
										healthCareId: info.healthCareId,
										report_docs: UploadedFiles,
										patientInstruction: this.state.patientInstruction,
									});
								}
							);
							let resArr = [];
							const resMultiData =
								await this.MultiBookingAppointmentLoopigUpdate(
									appointMentInputArr,
									0,
									resArr
								);
							const appResponse = await Promise.all(resMultiData);
							const { isError, errMsg } = checkError(appResponse?.[0]?.payload);
							if (isError) {
								this.context.setSnack({
									open: true,
									severity: AlertProps.severity.error,
									msg: errMsg,
									vertical: AlertProps.vertical.top,
									horizontal: AlertProps.horizontal.center,
									tone: true,
								});
							} else {
								this.setState({ ...this.state, sidebar: false }, () => {
									// this.props.history.push("/confirm_booking");
									// need to check
									this.props.history.push({
										pathname: Routes.confirmBooking,
										state: {
											// personId: this.props.location?.state?.userId,
											// mobileNo: this.props.location?.state?.mobileNo,
											// appointmentId: appResponse,
											appointmentInfo: appResponse,
											// patientId: this.props.location?.state?.patientId,
											// walkin: this.props.location?.state?.walkin,
											isMulti: true,
											fromListing: this.props.location?.state?.unregister?.is_Unregister ? false : true,
										},
									});
								});
							}
						} else {
							const appointMentInput = {
								slotId: [info.value || 0],
								slotStart: info.dateStart || "",
								slotEnd: info.dateEnd || "",
								orgId: hospitalInfo?.org_Id,
								orgType: hospitalInfo?.orgType,
								resourcetype: info.resourcetype || "",
								resourcerole: info.resourcerole || "",
								resourcecode: info.resourcecode || "",
								info: this.state.appointmentReason,
								specialtyID:
									this?.props?.location?.state?.specialities?.map(
										(spec) => spec?.value
									) || [],
								personID: [personId],
								practitionerId: hospitalInfo?.isClinic
									? []
									: [hospitalInfo?.id],
								mobile: this.props.location?.state?.selectedUser?.mobile || "",
								email: this.props.location?.state?.selectedUser?.email || "",
								consultationMode: this.state.consultationMode.value,
								healthCareId: info.healthCareId,
								report_docs: UploadedFiles,
								patientInstruction: this.state.patientInstruction,
							};
							if (
								moment()
									.startOf("day")
									.diff(moment(info.dateStart * 1000), "d") === 0 &&
								this.state.selectedSlot?.info?.maxwalkin > 0
							) {
								appointMentInput["appointmentType"] = "walkin";
							}
							debugger;
							const appInfo = await this.props.APPOINTMENT_CREATE(
								appointMentInput
							);
							const { isError, errMsg } = checkError(appInfo.payload);
							if (isError) {
								this.context.setSnack({
									open: true,
									severity: AlertProps.severity.error,
									msg: errMsg,
									vertical: AlertProps.vertical.top,
									horizontal: AlertProps.horizontal.center,
									tone: true,
								});
							} else {
								if(this.props.location?.state?.referral){
                                    const payload = [
													{
													filter: { _id: this.props.location?.state?.refferalId },
													doc: {
														recipientBookingReqd: false,
													},
													},
												];
								 await this.props.ORGANIZATION_REFERRAL_REGISTRATION({payloaddata: payload[0]});
									}
								const appointmentId =
									appInfo.payload?.data?.appointment_response?.appointment
										?.Result?.[0]?.properties?.doc?.id || 0;
								this.setState({ ...this.state, sidebar: false }, () => {
									// this.props.history.push("/confirm_booking");
									this.props.visitData.setVisitDetails({
										encounter: {
											mode: this.state?.consultationMode?.value === "Direct Consultation" ? "direct" : this.state?.consultationMode?.value === "Video Consultation" ? "video" : "direct"
										},
										practitioner: {},
										device: {},
										helthcare: {},
										accompained: {},
									});
									this.props.history.push({

										pathname: this.getPathName(info.dateStart),
										state: {
											personId: this.props.location?.state?.userId,
											mobileNo: this.props.location?.state?.mobileNo,
											appointmentId: appointmentId,
											fromListing: true,
											patientId: this.props.location?.state?.patientId,
											from: (moment().startOf("day").diff(moment(info.dateStart * 1000), "d") === 0 && this.state.selectedSlot?.info?.maxwalkin > 0)?1:null,
											walkin: !(
												moment()
													.startOf("day")
													.diff(moment(info.dateStart * 1000), "d") === 0 &&
												this.state.selectedSlot?.info?.maxwalkin > 0
											),
											appointmentReason: this.state.appointmentReason
											// Ispatient: true
										},
									});
								});
							}
						}
					}
				}
			}
		}
	};

	MultiBookingAppointmentLoopigUpdate = async (arr, index, resArr) => {
		let Data = [];
		let res = await this.props.APPOINTMENT_CREATE(arr[index]);
		Data.push(res);
		if (arr[index + 1]) {
			let resarr = await this.MultiBookingAppointmentLoopigUpdate(
				arr,
				index + 1
			);
			Data = [...Data, ...resarr];
		}
		return Data;
	};

	changeGuestInfo = (type, field, val) => {
		const state = this.state;
		state[type][field] = val;
		this.setState(state);
	};

	changeState = (key, value) => {
		if(key === "data"){
			this.setState({
				[key]: value,
				loading:false
			});
		}else{
			this.setState({
				[key]: value,
			});
		}
	};

	getDocterListingDetails = async ({
		hospital,
		specialities,
		latitude,
		longtitude,
		date,
		doctorId,
	}) => {
		debugger;
		const promArr = [
      this.props.DOCTER_LISTING({
        hospital: hospital,
        specialities,
        latitude,
        longtitude,
        date,
        doctorId,
      }),
      this.props.GET_ALL_LANGS(),
    ];
    await Promise.all(promArr);
		this.setState(
			{
				...this.state,
				data: [],
				Tempdata: [],
			},
			() => {
				this.changeState("allLang", this?.props?.allLanguages?.data || []);
				this.changeState("data", this.props?.docterListing?.data || []);
				this.changeState("Tempdata", this.props?.docterListing?.data || []);
			}
		);
	};

	async componentDidMount() {
		await this.props.GET_COUNTRY_CODE_LIST();
		let permissionData_ = await checkWithCasbin(["appointmentconfirmation"]);
		let permissionData__ = await checkWithCasbin(["bookAppointment"]);
		this.setState({ ...this.state, permissionDa: permissionData_ , permissionData: permissionData__});		
		
		const Arr = localStorage.getItem("role_data");
		const arr1 = JSON.parse(atob(Arr))[localStorage.getItem("role_name")]
			.access;
		const permission = { callcenter: arr1 };
		const authorizer = new casbinjs.Authorizer("manual", permission);
		authorizer.setPermission(permission);
		const result = await authorizer.can("callcenter", "4");
		this.setState({ callcen: result });

		const patientID = this.props?.location?.state?.patientId || 0;
		const patientdata = await this.props.ORGANIZATION_FILTER_BY_PATIENT({
			patientid: this.props.location?.state?.patientId,
		});
		const referral_patientdata = await this.props.GET_FERERRAL({
			patientid: this.props.location?.state?.patientId,
			reffId: this.props.location?.state?.refferalId,
		});
		this.setState({
			Persondata: patientdata?.payload?.data,
			referralData: referral_patientdata?.payload?.data,
		});
		if (this.props.GET_FINANCIAL) {
			const fin_data = await this.props.GET_FINANCIAL({ patientId: patientID });
			if (fin_data?.payload?.data) {
				const fin_DATA = fin_data?.payload?.data?.map((v) => {
					const setdata = v?.payersprioritycoverage?.[0];
					return {
						pic: "images/Group 90507.svg",
						name: null,
						care_type: setdata?.guarantorid,
						title_name: setdata?.receivertype + ", " + setdata?.policyid,
					};
				});
				this.setState({
					selectOption: fin_DATA[0],
					fin_data: fin_DATA,
				});
			}
		}
		await this.props.REASON_FOR_APPOINTMENT();
		const { payload } = await this.props.GET_ALL_SPECIALITY_2();
		this.setState({ allSpeciality: payload.data });
		const userInfo = getUserInfo();
		const info = {
			age: 0,
			gender: "",
		};
		if (userInfo.type === "token") {
			let mobileNo = userInfo.mobile;
			if (
				this.props.location?.state?.selectedUser &&
				this.props.location?.state?.selectedUser?.birthDay
			) {
				info["age"] = moment().diff(
					moment(
						this.props.location?.state?.selectedUser?.birthDay,
						"DD/MM/YYYY"
					),
					"years"
				);
				info["gender"] = this.props.location?.state?.selectedUser?.gender;
				this.changeState("sideInfo", info);
			} else {
				this.props.READ_PERSON({ phone: mobileNo }).then((val) => {
					info["age"] = val?.payload?.data?.birthDay
						? moment().diff(
								moment(val?.payload?.data?.birthDay, "DD/MM/YYYY"),
								"years"
						  )
						: "";
					info["gender"] = val?.payload?.data?.gender;
					this.changeState("sideInfo", info);
				});
			}
		} else {
			info["age"] = moment().diff(
				moment(userInfo.info.age.val, "DD/MM/YYYY"),
				"years"
			);
			info["gender"] = userInfo.info.gender?.value || "";
			this.changeState("sideInfo", info);
		}
		if (
			this?.props?.location?.state &&
			!this.props.location?.state?.modifyUser
		) {
			const {
				hospital = [],
				specialities = [],
				location = {} /*symptoms, location*/,
				doctors = [],
			} = this?.props?.location?.state;
			// this.getDocterListingDetails({ hospital: hospital.value, specialities: [{ value: 1 }] })
			if (!doctors?.[0]?.value ? specialities.length > 0 : true) {
				this.getDocterListingDetails({
					hospital: hospital?.map((l) => l?.value),
					specialities: specialities.map((spec) => spec.value),
					latitude: location?.latitude || 0,
					longtitude: location?.longitude || 0,
					date: undefined,
					doctorId: doctors?.[0]?.value,
				});
			} else {
				window.location.href = "/";
				return;
			}
		} else {
			this.appointmentRead();
			this.props.REASON_FOR_APPT_CANCEL_MODIFY({ type: "APPMODIFYBYUSER" });
		}
	}

	handleClick = (e) => {
		if (this.overlayNode?.current?.contains(e.target)) {
			// inside click
			return;
		}
		// outside click
		this.setState({
			...this.state,
			openCloseHeader: false,
			indexToShow: null,
		});
	};
	async appointmentRead() {
		const { Slots, resourcecode, slotID, resourcetype } =
			this.props?.location?.state?.appointMentInfo;
		let start = Slots?.[0]?.start;
		if (this.props.location?.state?.newBook) {
			start = Math.floor(Date.now() / 1000);
		}
		if (this.props.location?.state?.fromCloseConsultation) {
			start = Slots?.[0]?.start;
		}
		const data = await this.props.APPOINTMENT_UPDATE_READ({
			start,
			id: [resourcecode?._id],
			slotID,
			HealthcareServiceID: [resourcecode?._id],
			type: resourcetype === "Practitioner" ? "practionerid" : "clinicid",
		});
		this.changeState("data", data?.payload?.data || []);
		const IsModifyUser = this.props?.location?.state?.modifyUser;
    const newBook = this.props?.location?.state?.newBook;
    if (IsModifyUser && !newBook) {
      const selected = this.props.reasonForAppointment?.data?.filter(d => {
        if (this.props?.location?.state?.appointMentInfo?.appointmentReason?.includes(d?.value)) {
          return true;
        }
        return false;
      });
      const uploadInfo = this.props?.location?.state?.appointMentInfo?.report_docs?.map(f => {
        return {
          name: f.fileName,
          type: f.filetype,
          fileUrl: getImgUrl(f.fileid),
          size: 0,
          originalData: f,
        }
      })
      this.changeState("uploadFiles", uploadInfo || []);
      this.changeState("appointmentReason", selected);
      this.changeState("patientInstruction", this.props?.location?.state?.appointMentInfo?.patientInstruction || "");
    }
	}

	shouldComponentUpdate(props, state) {
		return true;
	}

	toggleSideBar = (boolean) => {
		this.setState({ ...this.state, sidebar: boolean });
	};

	handleOpen = async (index) => {
		if (index === 1) {
			// await this.props.REGISTER_USERS();
			// const { data } = this.props?.docterListingBookingFor
		}
		this.setState({
			...this.state,
			collpaseOpen: index === this.state.collpaseOpen ? null : index,
		});
	};

	onHeaderClicked = (index) => {
		this.setState({
			openCloseHeader: !this.state.openCloseHeader,
			indexToShow: index ?? null,
		});
	};

	rightSideAllCardfun = (val, info) => {
		if (info && val) {
			this.setState({
				...this.state,
				rightSideAllCard: val,
				DoctorAndAppointmentSlotCard: val,
				selectedSlot: info,
				resetTimeSlot: null,
				iagree: false,
				consultationMode: info.info.isdirect
					? {
							value: "Direct Consultation",
							label: "Direct Consultation",
					  }
					: info.info.isvideo
					? {
							value: "Video Consultation",
							label: "Video Consultation",
					  }
					: {},
			});
		} else {
			this.setState({
				...this.state,
				rightSideAllCard: val,
				DoctorAndAppointmentSlotCard: val,
				resetTimeSlot: null,
				iagree: false,
				isMultipleAppointment: false,
				multiApppointmentInfo: {
					appointments: {
						startsOn: {
							date: "",
							slotSelect: {},
							milli: 0,
						},
						preferrence: {
							preferredDays: [],
							preferredWeeks: [],
						},
						occurence: {
							occurrenceHandle: "NoOfDays",
							occurrenceValue: 1,
						},
						reccurance: {
							reccuranceValue: 1,
							reccuranceType: { label: "Days", value: "days" },
						},
					},
					info: {},
					data: [],
					slotObj: {},
				},
			});
		}
	};

	genderOptions = [
		{ value: "Male", label: "Male" },
		{ value: "Female", label: "Female" },
		{ value: "Others", label: "Others" },
	];

	relationshipOptions = [
		{ value: "Father", label: "Father" },
		{ value: "Mother", label: "Mother" },
		{ value: "Brother", label: "Brother" },
		{ value: "Sister", label: "Sister" },
		{ value: "Others", label: "Others" },
	];

	goBack() {
		if (this.props?.guestInfo?.data?.type === 0) {
			this.setState({
				...this.state,
				sidebar: true,
				showOtpVerification: false,
				collpaseOpen: 0,
			});
		} else {
			this.setState({
				...this.state,
				showOtpVerification: false,
				collpaseOpen: 1,
			});
		}
	}

	handleMySelfSignUp() {
		const state = { ...this.state };
		state.guestBookerInfo.mobileNo = state.guestInfo.mobileNo;
		state.guestBookerInfo.name = state.guestInfo.name;
		state.guestBookerInfo.mailId = state.guestInfo.mailId;
		state.guestBookerInfo.dob = state.guestInfo.age.val;
		state.guestBookerInfo.gender = state.guestInfo.gender.value;
		const check = this.checkValidationsForMyself(state.guestBookerInfo);
		if (check) {
			this.setState({ ...state, error: {}, meageget: {} }, () => {
				this.showVerification(null, true);
			});
		}
	}

	checkValidationsForMyself = (guestBookerInfo) => {
		let {
			name = "",
			dob = "",
			gender = "",
			mobileNo = {},
			mailId = "",
			relationship = "",
		} = guestBookerInfo;
		if (typeof relationship === "object") {
			relationship = Object.keys(relationship)?.length === 0 && "";
		}
		if (
			name &&
			(this.props.guestInfo.data.type === 0 ? (dob ? true : false) : true) &&
			(this.props.guestInfo.data.type === 0 ? (gender ? true : false) : true) &&
			// mobileNo?.mobileNo?.length > 9 &&
			// mobileNo?.countryData?.callingCodes &&
			(mailId ? ValidateEmail(mailId) : true) &&
			(this.props.guestInfo.data.type === 1
				? relationship
					? true
					: false
				: true)
		) {
			let res__ = mobileNumberValidation(mobileNo?.mobileNo, mobileNo?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: res__,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			} else if (res__ === false) {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please enter valid mobile number",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			}
			return true;
		} else {
			this.setState({
				...this.state,
				error: {
					guestBookerInfo: {
						mobileNo: mobileNo?.mobileNo?.length > 9 ? false : true,
						dob:
							this.props.guestInfo.data.type === 0
								? dob?.length > 0
									? false
									: true
								: false,
						callingCodes: mobileNo?.countryData?.callingCodes ? false : true,
						name: name?.length > 0 ? false : true,
						gender:
							this.props.guestInfo.data.type === 0
								? gender
									? false
									: true
								: false,
						mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
						relationship:
							this.props.guestInfo.data.type === 1
								? relationship.length > 0
									? false
									: true
								: false,
					},
				},
			});
			return false;
		}
	};

	checkMobileValidations = (mobileNo) => {
		if (mobileNo && Object.keys(mobileNo)?.length) {
			// if (
			// 	mobileNo?.mobileNo?.length > 9 &&
			// 	mobileNo?.countryData?.callingCodes
			// ) {
			// 	return true;
			// } else {
			// 	return false;
			// }
			let res__ = mobileNumberValidation(mobileNo?.mobileNo, mobileNo?.countryData?.callingCodes);
			debugger
			if (res__ === "Invalid Country code") {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: res__,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			} else if (res__ === false) {
				this.props.alert.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: "Please enter valid mobile number",
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.right,
				});
				return
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	checkValidationsForOthers = () => {
		const {
			name = "",
			age = {},
			gender = {},
			mailId = "",
			mobileNo = {},
		} = this.state.guestInfo;

		if (
			name &&
			age?.val &&
			gender?.label &&
			(mailId ? ValidateEmail(mailId) : true) &&
			this.checkMobileValidations(mobileNo)
			// (mobileNo?.mobileNo ? mobileNo?.mobileNo?.length > 9 ? true : false : true) &&
			// (mobileNo?.countryData?.callingCodes ? mobileNo?.countryData?.callingCodes ? true : false : true)
		) {
			this.setState({ ...this.state, error: { guestInfo: {} } }, () =>
				this.handleOpen(1)
			);
		} else {
			this.setState({
				...this.state,
				error: {
					guestInfo: {
						name: name?.length > 0 ? false : true,
						dob: age?.val?.length > 0 ? false : true,
						gender: gender?.label ? false : true,
						mailId: mailId ? (ValidateEmail(mailId) ? false : true) : false,
						mobileNo:
							mobileNo?.mobileNo?.length > 9 &&
							mobileNo?.countryData?.callingCodes
								? false
								: mobileNo?.mobileNo || mobileNo?.countryData?.callingCodes
								? mobileNo?.mobileNo?.length > 9
									? false
									: true
								: false,
						callingCodes:
							mobileNo?.countryData?.callingCodes && mobileNo?.mobileNo
								? false
								: mobileNo?.countryData?.callingCodes || mobileNo?.mobileNo
								? mobileNo?.countryData?.callingCodes
									? false
									: true
								: false,
					},
				},
			});
			return false;
		}
	};

	sendOTPandConfirm = () => {
		const check = this.checkValidationsForMyself(this.state.guestBookerInfo);
		if (check) {
			this.setState({
				...this.state,
				error: { guestBookerInfo: {} },
        meageget: {},
			});
			this.showVerification(null, true);
		}
	};

	showVerification = async (index, value) => {
		if (!index && value) {
			const { mobileNo, name, mailId } = this.state.guestBookerInfo;
			await this.props.REGISTER_USERS({
				mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${
					mobileNo.mobileNo
				}`,
				firstName: name.split(" ")[0],
				lastName: name.split(" ")[1] || "",
				emailId: mailId,
			});
			const status = checkError(this.props.registerUsers);
			if (status.isError) {
				if (status.errMsg === "User already exists.") {
					await this.props.GENERATE_OTP({
						mobileNo: `+${mobileNo?.countryData?.callingCodes ?? "00"}${
							mobileNo.mobileNo
						}`,
					});
					const otpStatus = checkError(this.props.generateOTP);
					if (!otpStatus.isError) {
						console.log("Add member only ...");
						this.setState({
							...this.state,
							collpaseOpen: index,
							showOtpVerification: value,
							addBookBy: false,
						});
					} else {
						console.error(status.errMsg);
					}
				} else {
					console.error(status.errMsg);
				}
			} else {
				console.log("Add member and user only ...");
				this.setState({
					...this.state,
					collpaseOpen: index,
					showOtpVerification: value,
				});
			}
		}
	};

	resetMulti = () => {
		this.setState({
			...this.state,
			isMultipleAppointment: false,
			multiApppointmentInfo: {
				appointments: {
					startsOn: {
						date: "",
						slotSelect: {},
						milli: 0,
					},
					preferrence: {
						preferredDays: [],
						preferredWeeks: [],
					},
					occurence: {
						occurrenceHandle: "NoOfDays",
						occurrenceValue: 1,
					},
					reccurance: {
						reccuranceValue: 1,
						reccuranceType: { label: "Days", value: "days" },
					},
				},
				info: {},
				data: [],
				slotObj: {},
				filterData: {},
				uploadFiles: [],
			},
		});
	};

	changeScreen = async () => { 
		let UploadedFiles = [];
		let { uploadFiles } = this.state;
		const { info = {}, hospitalInfo = {} } = this.state.selectedSlot;
		UploadedFiles = await uploadImage(uploadFiles);
		const { mobileNo } = this.state.guestBookerInfo;
		let number = (!this.props.location?.state?.unregister?.is_Unregister && this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? this.props.location?.state?.userNo : getMobileNumber(
			 this.props.location?.state?.unregister?.mobileNo || mobileNo
		);
		if (this.state.appointmentUpdate) {
			number = this.state.otpNumber;
		} else {
			number = (!this.props.location?.state?.unregister?.is_Unregister && this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? this.props.location?.state?.userNo : getMobileNumber(
				this.props.location?.state?.unregister?.mobileNo
			);
		}
		let d = await this.props.SIGN_IN({
			mobileNo: number,
			otp: this.state.otp,
		});
		const status = checkError(this.props.signIn);
		let appointmentId = 0;
		let bookerId = 0;
		if (status.isError) {
			this.context.setSnack({
				open: true,
				severity: AlertProps.severity.error,
				msg: status.errMsg,
				vertical: AlertProps.vertical.top,
				horizontal: AlertProps.horizontal.center,
				tone: true,
			});
		} else {
			if (this.state.appointmentUpdate && !this.props?.location?.state?.fromCloseConsultation) {
				let UploadedFiles = [];
				let { uploadFiles } = this.state;
				UploadedFiles = await uploadImage(uploadFiles);
				const appointMentInfo = this.state.oldSlot.appInfoFetched;
				appointMentInfo["consultationMode"] = this.state.consultationMode.value;
				appointMentInfo["slotID"] = [this.state.selectedSlot.info?.value];
				appointMentInfo["start"] = this.state.selectedSlot.info?.dateStart;
				appointMentInfo["end"] = this.state.selectedSlot.info?.dateEnd;
				appointMentInfo["cancelationReason"] =
					[this.state.modifyAppointmentReason?._id] ?? [];
				appointMentInfo["requestedPeriod"] = [
					{
						start: this.state.selectedSlot.info?.dateStart,
						end: this.state.selectedSlot.info?.dateEnd,
					},
				];
				appointMentInfo["oldStart"] =
					this.props?.location?.state?.appointMentInfo?.start || 0;
				appointMentInfo["oldEnd"] =
					this.props?.location?.state?.appointMentInfo?.start || 0;
				appointMentInfo.orgid = appointMentInfo["orgid"]._id;
				appointMentInfo.orgtype = appointMentInfo.orgtype?._id;
				appointMentInfo.resourcecode = appointMentInfo.resourcecode._id;
				appointMentInfo.appointmentReason = this.state.appointmentReason?.map(i => i?.value);
				appointMentInfo.report_docs = UploadedFiles;
				appointMentInfo.patientInstruction = this.state.patientInstruction;
				const updatedData = await this.props.APPOINTMENT_UPDATE(
					appointMentInfo
				);
				const updateStatus = checkError(updatedData?.payload);
				if (updateStatus.isError) {
					this.context.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: updateStatus.errMsg,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
				} else {
					this.setState({ ...this.state, sidebar: false }, () => {
						const appointmentId =
							updatedData.payload?.data?.appointment_response?.appointment
								?.Result?.[0]?.properties?.doc?.id || 0;
						// this.props.history.push("/confirm_booking");

						this.props.history.push({
							pathname: Routes.confirmBooking,
							state: {
								personId: this.props.location?.state?.userId,
								mobileNo: this.props.location?.state?.mobileNo,
								appointmentId: appointmentId,
								modfieded: this.props?.location?.state?.fromCloseConsultation ? false : true,
								patientId: this.props.location?.state?.patientId,
								unregister:
									this.props.location?.state?.unregister?.is_Unregister,
								walkin: !(
									moment()
										.startOf("day")
										.diff(moment(info?.dateStart * 1000), "d") === 0 &&
									this.state.selectedSlot?.info?.maxwalkin > 0
								),
							},
						});
					});
				}
				return;
			}
			if (this.props.location?.state?.unregister?.is_Unregister || this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1 || this.props?.location?.state?.fromCloseConsultation) {
				if(!(this.props.location?.state?.unregister?.is_Unregister) || this.props?.location?.state?.fromCloseConsultation){
					debugger
					const appointMentInput = {
						slotId: [info.value || 0],
						slotStart: info.dateStart || "",
						slotEnd: info.dateEnd || "",
						orgId: hospitalInfo?.org_Id,
						orgType: hospitalInfo?.orgType,
						resourcetype: info.resourcetype || "",
						resourcerole: info.resourcerole || "",
						resourcecode: info.resourcecode || "",
						info: this.state.appointmentReason,
						specialtyID: this.props?.location?.state?.fromCloseConsultation ? this.props?.location?.state?.specialities :
							this?.props?.location?.state?.specialities?.map(
								(spec) => spec?.value
							) || [],
						personID: [this.props.location.state.userId || 0],
						practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
						mobile: (!this.props.location?.state?.unregister?.is_Unregister && !this.props?.location?.state?.fromCloseConsultation && this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? this.props.location?.state?.userNo : this.props?.location?.state?.fromCloseConsultation ? this.props?.location?.state?.mobNoo :  getMobileNumber(
							this.props.location?.state?.unregister?.mobileNo
						),
						email: this.state.guestInfo?.mailId || this.props?.location?.state?.emaill,
						consultationMode: this.state.consultationMode.value,
						healthCareId: info.healthCareId,
						report_docs: UploadedFiles,
						patientInstruction: this.state.patientInstruction,
					};
					if (
						moment()
							.startOf("day")
							.diff(moment(info.dateStart * 1000), "d") === 0 &&
						this.state.selectedSlot?.info?.maxwalkin > 0
					) {
						appointMentInput["appointmentType"] = "walkin";
					}
					debugger;
					const appInfo = await this.props.APPOINTMENT_CREATE(appointMentInput);
					const { isError, errMsg } = checkError(appInfo.payload);
					if (isError) {
						this.context.setSnack({
							open: true,
							severity: AlertProps.severity.error,
							msg: errMsg,
							vertical: AlertProps.vertical.top,
							horizontal: AlertProps.horizontal.center,
							tone: true,
						});
					} else {
						const appointmentId =
							appInfo.payload?.data?.appointment_response?.appointment
								?.Result?.[0]?.properties?.doc?.id || 0;
						this.setState({ ...this.state, sidebar: false }, () => {
							// this.props.history.push("/confirm_booking");
							this.props.history.push({
								pathname: Routes.confirmBooking,
								// !(
								//   moment()
								//     .startOf("day")
								//     .diff(moment(info.dateStart * 1000), "d") === 0 &&
								//   this.state.selectedSlot?.info?.maxwalkin > 0
								// )
								//   ? Routes.confirmBooking
								//   : Routes.patientVisitDetails,
								state: {
									personId: this.props.location?.state?.userId,
									mobileNo: this.props.location?.state?.mobileNo,
									appointmentId: appointmentId,
									patientId: this.props.location?.state?.patientId,
									fromListing: this.props.location?.state?.unregister?.is_Unregister ? false : true,
									walkin: !(
										moment()
											.startOf("day")
											.diff(moment(info.dateStart * 1000), "d") === 0 &&
										this.state.selectedSlot?.info?.maxwalkin > 0
									),
									unregister: this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1 ? false : 
										this.props.location?.state?.unregister?.is_Unregister,
								},
							});
						});
					}
	
					return;
				} else {
				const guestBookerAdd = {
					phone: getMobileNumber(
						this.props.location?.state?.unregister?.mobileNo
					),
					dob: this.props.location?.state?.unregister?.dob || "01/01/2000",
					firstName: this.props.location?.state?.unregister?.patientName,
					lastName: "",
					gender:
						this.props.location?.state?.unregister?.gender?.value || "Male",
					email: this.props.location?.state?.unregister?.emailId || "",
				};
				const {
					error,
					data: bookerData,
					getData,
				} = await upsertPerson(
					this.props.READ_PERSON,
					this.props.UPSERT_PERSON,
					guestBookerAdd
				);
				if (error && bookerData.includes("User Already Exists ")) {
					bookerId = getData.payload?.data?.id || 0;
				} else if (error) {
					this.context.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: bookerData,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
					return;
				} else {
					bookerId = bookerData.payload?.data?.id || 0;
				}

				const appointMentInput = {
					slotId: [info.value || 0],
					slotStart: info.dateStart || "",
					slotEnd: info.dateEnd || "",
					orgId: hospitalInfo?.org_Id,
					orgType: hospitalInfo?.orgType,
					resourcetype: info.resourcetype || "",
					resourcerole: info.resourcerole || "",
					resourcecode: info.resourcecode || "",
					info: this.state.appointmentReason,
					specialtyID:
						this?.props?.location?.state?.specialities?.map(
							(spec) => spec?.value
						) || [],
					personID: [bookerId || 0],
					practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
					mobile: getMobileNumber(
						this.props.location?.state?.unregister?.mobileNo
					),
					email: this.state.guestInfo?.mailId,
					consultationMode: this.state.consultationMode.value,
					healthCareId: info.healthCareId,
					report_docs: UploadedFiles,
					patientInstruction: this.state.patientInstruction,
				};
				if (
					moment()
						.startOf("day")
						.diff(moment(info.dateStart * 1000), "d") === 0 &&
					this.state.selectedSlot?.info?.maxwalkin > 0
				) {
					appointMentInput["appointmentType"] = "walkin";
				}
				debugger;
				const appInfo = await this.props.APPOINTMENT_CREATE(appointMentInput);
				const { isError, errMsg } = checkError(appInfo.payload);
				if (isError) {
					this.context.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: errMsg,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
				} else {
					const appointmentId =
						appInfo.payload?.data?.appointment_response?.appointment
							?.Result?.[0]?.properties?.doc?.id || 0;
					this.setState({ ...this.state, sidebar: false }, () => {
						// this.props.history.push("/confirm_booking");
						this.props.history.push({
							pathname: Routes.confirmBooking,
							// !(
							//   moment()
							//     .startOf("day")
							//     .diff(moment(info.dateStart * 1000), "d") === 0 &&
							//   this.state.selectedSlot?.info?.maxwalkin > 0
							// )
							//   ? Routes.confirmBooking
							//   : Routes.patientVisitDetails,
							state: {
								personId: this.props.location?.state?.userId,
								mobileNo: this.props.location?.state?.mobileNo,
								appointmentId: appointmentId,
								patientId: this.props.location?.state?.patientId,
								fromListing: this.props.location?.state?.unregister?.is_Unregister ? false : true,
								walkin: !(
									moment()
										.startOf("day")
										.diff(moment(info.dateStart * 1000), "d") === 0 &&
									this.state.selectedSlot?.info?.maxwalkin > 0
								),
								unregister:
									this.props.location?.state?.unregister?.is_Unregister,
							},
						});
					});
				}

				return;
			}
			}

			if (this.props?.signIn?.data?.access_token) {
				localStorage.removeItem("guestInfo");
				localStorage.setItem("token", this.props?.signIn?.data?.access_token);
			}
			if (this.state.addBookBy) {
				const guestBookerAdd = {
					phone: getMobileNumber(this.state.guestBookerInfo.mobileNo),
					dob: this.state.guestBookerInfo.dob || "01/01/2000",
					firstName: this.state.guestBookerInfo.name.split(" ")[0],
					lastName:
						this.state.guestBookerInfo.name.split(" ")[1] ||
						"",
					gender: this.state.guestBookerInfo.gender || "Male",
					email: this.state.guestBookerInfo?.mailId || "",
				};
				const {
					error,
					data: bookerData,
					getData,
				} = await upsertPerson(
					this.props.READ_PERSON,
					this.props.UPSERT_PERSON,
					guestBookerAdd
				);
				if (error && bookerData.includes("User Already Exists ")) {
					bookerId = getData.payload?.data?.id || 0;
				} else if (error) {
					this.context.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: bookerData,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
					return;
				} else {
					bookerId = bookerData.payload?.data?.id || 0;
				}
			}

			const bookData = await this.props.READ_PERSON({
				phone: getMobileNumber(this.state.guestBookerInfo.mobileNo),
			});
			if (this.props?.guestInfo?.data?.type === 0) {
				if (!this.state.addBookBy) {
					bookerId = bookData?.payload?.data?.id || 0;
					localStorage.setItem("idval", bookerId);
				}
			} else {
				const guestInfoAdd = {
					phone: getMobileNumber(this.state.guestInfo.mobileNo),
					dob: this.state.guestInfo.age.val,
					firstName: this.state.guestInfo.name.split(" ")[0],
					lastName:
						this.state.guestInfo.name.split(" ")[1] || "",
					gender: this.state.guestInfo?.gender?.value,
					email: this.state.guestInfo?.mailId || "",
				};
				const { error, data } = await upsertPerson(
					this.props.READ_PERSON,
					this.props.UPSERT_PERSON,
					guestInfoAdd
				);
				if (error) {
					this.context.setSnack({
						open: true,
						severity: AlertProps.severity.error,
						msg: data,
						vertical: AlertProps.vertical.top,
						horizontal: AlertProps.horizontal.center,
						tone: true,
					});
					return;
				} else {
					await this.props.ADD_RELATED_PERSON({
						...guestInfoAdd,
						relationType: this.state.guestBookerInfo?.relationship?.value || "",
						relationPersonId: [bookData?.payload?.data?.id || 0],
						personId: data.payload?.data?.id || 0,
					});
					bookerId = data.payload?.data?.id || 0;
				}
			}

			const appointMentInput = {
				slotId: [info.value || 0],
				slotStart: info.dateStart || "",
				slotEnd: info.dateEnd || "",
				orgId: hospitalInfo?.org_Id,
				orgType: hospitalInfo?.orgType,
				resourcetype: info.resourcetype || "",
				resourcerole: info.resourcerole || "",
				resourcecode: info.resourcecode || "",
				info: this.state.appointmentReason,
				specialtyID:
					this?.props?.location?.state?.specialities?.map(
						(spec) => spec?.value
					) || [],
				personID: [bookerId || 0],
				practitionerId: hospitalInfo?.isClinic ? [] : [hospitalInfo?.id],
				mobile: getMobileNumber(this.state.guestInfo?.mobileNo),
				email: this.state.guestInfo?.mailId,
				consultationMode: this.state.consultationMode.value,
				healthCareId: info.healthCareId,
				report_docs: UploadedFiles,
				patientInstruction: this.state.patientInstruction,
			};
			if (
				moment()
					.startOf("day")
					.diff(moment(info.dateStart * 1000), "d") === 0 &&
				this.state.selectedSlot?.info?.maxwalkin > 0
			) {
				appointMentInput["appointmentType"] = "walkin";
			}
			debugger;
			const appInfo = await this.props.APPOINTMENT_CREATE(appointMentInput);
			const { isError, errMsg } = checkError(appInfo.payload);
			if (isError) {
				this.context.setSnack({
					open: true,
					severity: AlertProps.severity.error,
					msg: errMsg,
					vertical: AlertProps.vertical.top,
					horizontal: AlertProps.horizontal.center,
					tone: true,
				});
			} else {
				const appointmentId =
					appInfo.payload?.data?.appointment_response?.appointment?.Result?.[0]
						?.properties?.doc?.id || 0;
				this.setState({ ...this.state, sidebar: false }, () => {
					// this.props.history.push("/confirm_booking");
					this.props.visitData.setVisitDetails({
						encounter: {
							mode: this.state?.consultationMode?.value === "Direct Consultation" ? "direct" : this.state?.consultationMode?.value === "Video Consultation" ? "video" : "direct"
						},
						practitioner: {},
						device: {},
						helthcare: {},
						accompained: {},
					});
					this.props.history.push({

						pathname: this.getPathName(info.dateStart),
						state: {
							personId: this.props.location?.state?.userId,
							mobileNo: this.props.location?.state?.mobileNo,
							appointmentId: appointmentId,
							patientId: this.props.location?.state?.patientId,
							walkin: !(
								moment()
									.startOf("day")
									.diff(moment(info.dateStart * 1000), "d") === 0 &&
								this.state.selectedSlot?.info?.maxwalkin > 0
							),
							fromListing: true,
						},
					});
				});
			}
		}
	};

	handleOpenProfile = async () => {
		await this.props.DOCTER_LISTING_PROFILE();
		const { data } = this.props?.docterListingProfile;
		this.setState({
			profileDetails: data,
		});
	};
	handleresendOTP = async () => {
		const number = this.state.otpNumber;
		const meageget = await this.props.RESEND_OTP({
			mobileNo: number,
		});
		this.changeState("meageget", meageget);
	};
	handlepapover = (e) => {
		if (this.state.opencalendar) {
			this.handlepapoverclose();
		} else {
			this.setState({ opencalendar: e.currentTarget });
		}
	};
	handlepapoverclose = (val) => {
		if (val) {
			this.setState({ opencalendar: null });
		}
	};
	filterCalendar = (e) => {
		this.setState({
			...this.state,
			openCloseCalendar: e.currentTarget,
		});
	};
	handleClose = (val) => {
		this.setState({
			...this.state,
			openCloseCalendar: val,
		});
	};
	mainfilter = () => {
		this.setState({
			...this.state,
			filter: !this.state.filter,
		});
	};
	onKeyDown = (e) => {
		if (
			!(
				(e.keyCode >= 48 && e.keyCode <= 57) ||
				e.keyCode === 8 ||
				e.keyCode === 46 ||
				e.keyCode === 37 ||
				e.keyCode === 39
			)
		) {
			e.preventDefault();
		}
	};

	async handleApply() {
		let { monthCalendarInputValue, selectedDate } = this.state;
		const {
			hospital = [],
			specialities = [],
			location = {},
			doctors = [],
		} = this?.props?.location?.state;
		let date = moment(selectedDate.val, "DD-MM-YYYY");
		if (monthCalendarInputValue) {
			date = moment(
				new Date(
					moment(date, "MM-DD-YYYY").add(
						parseInt(monthCalendarInputValue),
						"days"
					)
				)
			).format("MM-DD-YYYY");
		}
		this.setState(
			{
				applyedDate: date,
			},
			() => {
				this.getDocterListingDetails({
					hospital: hospital?.map((l) => l?.value),
					specialities: specialities.map((spec) => spec.value),
					latitude: location?.latitude || 0,
					longtitude: location?.longitude || 0,
					date: date,
					doctorId: doctors?.[0]?.value,
				});
				this.handleClose(false);
			}
		);
	}

	ApplyFilter = (filterData) => {
		this.changeState("data", []);
    const {
      consultmode,
      speciality,
      gender,
      lang,
    } = filterListingData(filterData);
    const {
      hospital = [],
      location = {},
      doctors = [],
      /*symptoms, location*/
    } = this?.props?.location?.state;
    this.props.DOCTER_LISTING({
      hospital: hospital?.map((l) => l?.value),
      specialities: speciality || [],
      latitude: location?.latitude || 0,
      longtitude: location?.longitude || 0,
      date: undefined,
      doctorId: doctors?.[0]?.value,
      consultmode,
      gender,
      lang,
    }).then(d => {
      this.changeState("data", d?.payload?.data || []);
      this.setState({
        ...this.state,
        filterData,
      }, () => this.mainfilter());
    });
	};

	uploadFun = (files) => {
		this.setState({
			uploadFiles: files,
		});
	};

	// async handleApply() {
	// 	let { monthCalendarInputValue, selectedDate } = this.state;
	// 	const {
	// 		hospital = [],
	// 		specialities = [],
	// 		location = {},
	// 		doctors = [],
	// 	} = this?.props?.location?.state;
	// 	let date = moment(selectedDate.val, "DD-MM-YYYY");
	// 	if (monthCalendarInputValue) {
	// 		date = moment(
	// 			new Date(
	// 				moment(date, "MM-DD-YYYY").add(
	// 					parseInt(monthCalendarInputValue),
	// 					"days"
	// 				)
	// 			)
	// 		).format("MM-DD-YYYY");
	// 	}
	// 	this.setState(
	// 		{
	// 			applyedDate: date,
	// 		},
	// 		() => {
	// 			this.getDocterListingDetails({
	// 				hospital: hospital?.map((l) => l?.value),
	// 				specialities: specialities.map((spec) => spec.value),
	// 				latitude: location?.latitude || 0,
	// 				longtitude: location?.longitude || 0,
	// 				date: date,
	// 				doctorId: doctors?.[0]?.value,
	// 			});
	// 			this.handleClose(false);
	// 		}
	// 	);
	// }

	render() {
		//
		const info = getUserInfo();
		const { classes } = this.props;
		const { filterData, allSpeciality } = this.state;
		const open = Boolean(this.state.openCloseCalendar);
		const id = open ? "simple-popover" : undefined;
		const IsModifyUser = this.props?.location?.state?.modifyUser;
		const newBook = this.props?.location?.state?.newBook;
		const fromCloseConsultation = this.props?.location?.state?.fromCloseConsultation;
		console.log('this.state',this.state)
		return (
			<div id="doctor_listing_parent_div">
				<Container
					id="doctor_listing_parent_container"
					key={"0"}
					name="container"
					fluid={true}
					style={{
						backgroundColor: "#F3F4F7",
						padding: "0px",
						width: "100%",
					}}
				>
					<Row
						id="doctor_listing_parent_row"
						key={"0"}
						alignItems="stretch"
						direction="row"
						justify="flex-start"
						spacing="2"
						style={{ backgroundColor: "", width: "100%" }}
					>
						<Overlay
							id="doctor_listing_consultation_overlay"
							open={this.state.openCloseHeader}
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							// onClose={() => this.onHeaderClicked()}
							onClose={(e) => this.handleClick(e)}
						>
							<div ref={this.overlayNode} style={{ width: "100%" }}>
								<DoctorConsultation
									parent_id={"doctor_listing"}
									hideBackground={true}
									onValueChange={this.getDocterListingDetails}
									onHeaderClicked={this.onHeaderClicked}
									values={this.props?.location?.state || false}
									indexToShow={this.state.indexToShow}
								/>
							</div>
						</Overlay>
						<Header
							parent_id={"doctor_listing"}
							IsModifyUser={
								IsModifyUser || !!this.props?.location?.state?.referral
							}
							onHeaderClicked={this.onHeaderClicked}
							values={this.props?.location?.state}
						/>
					</Row>
					{this.props?.location?.state?.referral && (
						<Row style={{ padding: "6px 0px" }}>
							<Col
								id="doctor_listing_patientlist_view_col"
								lg={4}
								md={4}
								inLineStyles={{ padding: 5 }}
							>
								<PatientListView
									parent_id={"doctor_listing"}
									noright={true}
									Persondata={this.state.Persondata?.[0]}
								/>
							</Col>
							<Col
								id="doctor_listing_patientcare_col"
								lg={4}
								md={4}
								inLineStyles={{ padding: 5 }}
							>
								<PatientCareTypeCard
									parent_id={"doctor_listing"}
									careList={this.state.fin_data}
									selected={this.state.selectOption}
									onChange={(data) => this.changeState("selectOption", data)}
									padding
								/>
							</Col>
							<Col
								id="doctor_listing_referral_listview_col"
								lg={4}
								md={4}
								inLineStyles={{ padding: 5 }}
							>
								<ReferralListView
									parent_id={"doctor_listing"}
									noright={true}
									imgUrl={"images/icons8-Referral.svg"}
									ReferralData={this.state?.referralData?.[0]}
									padding
								/>
							</Col>
						</Row>
					)}
					<Row
						id="doctor_listing_appointment_row"
						key={"1"}
						alignItems="stretch"
						direction="row"
						justify="flex-start"
						spacing="2"
					>
						<Col
							id="doctor_listing_doctor_available_col_one"
							key={"0"}
							name="qdm-col-12"
							alignItems="stretch"
							direction="row"
							justify="flex-start"
							textAlign="left"
							lg="8"
							xs="12"
							md="8"
							sm="12"
							style={{ padding: "0px", backgroundColor: "#F3F4F7" }}
						>
							<Col
								id="doctor_listing_doctor_available_col"
								key={"0"}
								name="qdm-col-12"
								alignItems="stretch"
								direction="row"
								justify="space-between"
								textAlign="left"
								lg="12"
								xs="12"
								md="12"
								sm="12"
								style={{
									padding: "16px 16px 16px 36px",
									backgroundColor: "#F3F4F7",
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Div
									id="doctor_listing_doctor_available_div"
									// style={{ display: "flex", alignItems: "center" }}
								>
									<Text
										id="doctor_listing_doctor_available_text"
										style={{ margin: "0px", fontSize: 16 }}
										className={"pc_semibold"}
									>
										{IsModifyUser ? (
											<Div id="doctor_listing_appointment_div">
												<Text
													style={{
														margin: "0px",
														fontSize: 16,
													}}
													className={"pc_semibold"}
												>
													{newBook ? (fromCloseConsultation ? "Followup Booking"  : "Book Again") : "Modify Appointment"}
												</Text>
												<br />
												<span
													id="doctor_listing_appointment_span"
													style={{
														color: "#B6B6B6",
														fontSize: 14,
														fontWeight: 400,
													}}
													className={"pc_regular"}
												>
													{newBook ?
													(fromCloseConsultation ? "Book a Followup appointment"  : 
														 "Book a new appointment.")
														: "If you like to change the schedule. You can change Here."}
												</span>
											</Div>
										) : (
											"Doctors Available"
										)}
									</Text>

									{!IsModifyUser && (
										<Text
											id="doctor_listing_data_text"
											style={{
												marginLeft: "8px",
												marginTop: "2px",
												fontSize: 16,
												color: "#6F6F6F",
											}}
											className="pc_regular"
										>
											({this.state.data.length})
										</Text>
									)}
								</Div>

								{!IsModifyUser && (
									<Div
										id="doctor_listing_filter_calender_div"
										style={{
											display: "flex",
											// padding: "8px 8px",
											// backgroundColor: "#F5F5F5",
											// borderRadius: 8,
											// border: "1px solid #F0F0F0",
										}}
									>
										<Popup
											id={id}
											open={open}
											anchorEl={this.state.openCloseCalendar}
											onClose={() => this.handleClose(false)}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "right",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "right",
											}}
											style={{ marginTop: "1%", marginLeft: "2%" }}
										>
											<div
												id="doctor_listing_filter_calender_sub_div"
												style={{
													width: 300,
													backgroundColor: "#F9F9F9",
													padding: "10px",
												}}
											>
												{/* <Calendars
													id="doctor_listing_filter_calenders"
													minDate={new Date()}
													value={new Date(this.state.selectedDate.unix * 1000)}
													allowPartialRange
													onClickMonth={(value, event) => {
														this.handlepapoverclose(false);
													}}
													onClickYear={(value, event) => {
														this.handlepapoverclose(false);
													}}
													onClickWeekNumber={(value, event) => {
														this.handlepapoverclose(false);
													}}
													className={classes.monthCalendar}
													onChange={(value, event) => {
														const date = {
															val: moment(value).format("DD-MM-YYYY"),
															unix: moment(value).startOf("date").unix(),
														};
														this.changeState("monthCalendarInputValue", "");
														this.changeState("selectedDate", date);
													}}
												/> */}

												<CustomDatePicker
													selectedDate = {new Date(this.state.selectedDate.unix * 1000)}
													minDate={new Date()}
													handleChange={(value) => {const date = {
														val: moment(value).format("DD-MM-YYYY"),
														unix: moment(value).startOf("date").unix(),
													};
													this.changeState("monthCalendarInputValue", "");
													this.changeState("selectedDate", date);}}
												/>
												{/* <DatePicker
													minDate={new Date()}
													selected = {new Date(this.state.selectedDate.unix * 1000)}
													monthsShown={1}
													inline
													showMonthDropdown
													showYearDropdown
													onChange={(value, event) => {
														const date = {
															val: moment(value).format("DD-MM-YYYY"),
															unix: moment(value).startOf("date").unix(),
														};
														this.changeState("monthCalendarInputValue", "");
														this.changeState("selectedDate", date);
													}}
												/> */}
											</div>
											<div
												id="doctor_listing_filter_div"
												style={{
													backgroundColor: "#F9F9F9",
												}}
											>
												<Divider
													id="doctor_listing_or_divider"
													variant="fullWidth"
													textWidth={0}
													label={"or"}
													textColor="#B6B6B6"
													borderColor="#B6B6B6"
													className={classes.divider}
												/>
											</div>
											<div
												id="doctor_listing_ Earliest_div"
												style={{
													backgroundColor: "#F9F9F9",
													textAlign: "center",
													display: "flex",
													alignItems: "center",
													justifyContent: "center",
													paddingTop: "3%",
												}}
											>
												<div
													id="doctor_listing_ Earliest_sub_div"
													style={{
														backgroundColor: "#fff",
														padding: "4px",
														border: "1px solid #E0E0E0",
														borderRadius: 4,
														width: "94%",
														display: "flex",
														justifyContent: "space-around",
														alignItems: "center",
														height: "40px",
													}}
												>
													<span
														id="doctor_listing_ Earliest_span"
														style={{ fontSize: 14 }}
													>
														Earliest available after
													</span>{" "}
													<input
														id="doctor_listing_ Earliest_input"
														style={{
															width: 40,
															height: 24,
															border: "1px solid #e0e0e0",
															outline: "none",
															borderRadius: 3,
														}}
														placeholder="000"
														className={classes.earliestInput}
														value={this.state.monthCalendarInputValue}
														maxLength={3}
														onChange={(e) => {
															const date = {
																val: moment().format("DD-MM-YYYY"),
																unix: moment().startOf("date").unix(),
															};
															if (date.unix !== this.state.selectedDate.unix) {
																this.changeState("selectedDate", date);
															}
															this.changeState(
																"monthCalendarInputValue",
																e.target.value
															);
														}}
														onKeyDown={(e) => this.onKeyDown(e)}
													/>{" "}
													<span
														id="doctor_listing_days_span"
														style={{ fontSize: 14, fontWeight: 600 }}
													>
														Days
													</span>
												</div>
											</div>
											<div
												id="doctor_listing_description_div"
												style={{ backgroundColor: "#F9F9F9" }}
											>
												<div
													id="doctor_listing_description_sub_div"
													style={{
														display: "flex",
														justifyContent: "flex-end",
													}}
												>
													<button
														id="doctor_listing_clear_button"
														className={classes.clear}
														onClick={() => {
															this.changeState("monthCalendarInputValue", "");
														}}
													>
														Clear
													</button>
													<button
														id="doctor_listing_apply_button"
														className={classes.apply}
														onClick={() => this.handleApply()}
													>
														Apply
													</button>
												</div>
											</div>
										</Popup>
										<Div
											id="doctor_listing_filtercalendar_div"
											style={{
												backgroundColor: "#ffffff",
												borderColor: "rgb(217 217 217)",
												padding: "9px",
												border: "1px solid rgb(217 217 217)",
												borderRadius: "4px",
												cursor: "pointer",
												width: "32px",
												height: "32px",
												marginRight: 8,
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											onClick={(e) => this.filterCalendar(e)}
										>
											<Calendar
												id="doctor_listing_filtercalendar_calendar"
												// color={false ? "#0071F2" : "#6f6f6f"}
												color="#2A3C50"
											/>
										</Div>
										<Div
											id="doctor_listing_mainfilter_div"
											style={{
												backgroundColor: "#ffffff",
												borderColor: "rgb(217 217 217)",
												padding: "9px",
												border: "1px solid rgb(217 217 217)",
												borderRadius: "4px",
												cursor: "pointer",
												width: "32px",
												height: "32px",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
											onClick={() => this.mainfilter()}
										>
											<FilterIcon
												id="doctor_listing_funnel_img"
												color="#2A3C50"
											/>
										</Div>
									</Div>
								)}
							</Col>


							<Div
								id="doctor_listing_doctorcard_div"
								style={{
									height: "calc(100vh - 148px)",
									overflowY: "overlay",
									padding: "0px 16px 0px 36px",
									display: this.state.loading ? "flex" : "block",
									alignItems: "center",
									justifyContent:"center"
								}}
							>
								<LoadingComp loading={this.state.loading || this.props?.docterListing?.loading} >
								{this.state.data.length > 0 ?  
									this.state.data &&
									this.state.data.map((d, key) =>
										!d ? null : (
											<DoctorCardDetail
												parent_id={"doctor_listing_" + key}
												handleChange={this.changeState}
                        						resetMulti={this.resetMulti}
												data={d}
												keydata={key}
												selectedDate={this.state.applyedDate}
												selectedSlot={this.state.selectedSlotId}
												selectedSlotId={this.state.selectedSlotId}
												selectedSlotOrd={this.state.selectedSlotOrd}
												oldAppointment={
													this.props.location?.state?.appointMentInfo || {}
												}
												// orgId={this?.props?.location?.state?.hospital?.[0]?.value || 0}
												values={this.state}
												handleOpenProfile={this.handleOpenProfile}
												rightSideAllCardfun={this.rightSideAllCardfun}
												rightSideAllCard={this.state.rightSideAllCard}
												totlenght={this.state.data.length}
											/>
										)
									): <div style={{padding: "0px 16px 16px 36px",fontWeight: 500}}>No result found</div> }
							
									</LoadingComp>
							</Div>
						</Col>

						<Col
							id="doctor_listing_parent_col"
							key={"1"}
							name="qdm-col-12"
							alignItems="stretch"
							direction="row"
							justify="flex-start"
							textAlign="left"
							lg="4"
							xs="12"
							md="4"
							sm="12"
							style={{
								backgroundColor: "#F3F4F7",
								padding: "0px",
								// paddingBottom: "0",
							}}
						>
							<div style={{ paddingTop: "16px" }}>
								<Div
									id="doctor_listing_avatar_parent_div"
									style={{
										height: "calc(100vh - 82px)",
										overflowY: "auto",
										padding: "0px 16px",
									}}
								>
									<Div
										id="doctor_listing_avatar_parent_sub_div"
										style={{
											padding: 10,
											marginBottom: 8,
											backgroundColor: "white",
											boxShadow: "none",
											border: "1.5px solid #E0E0E0",
											borderRadius: 8,
											display: "flex",
											alignItems: "center",
										}}
									>
										<Avatar
											id="doctor_listing_avatar"
											key={"0"}
											variant="circle"
											src={getImgUrl(
                        this.props.location?.state?.selectedUser?.fileid
                      )}
											letter={this.props.location?.state?.selectedUser?.name || this.props.location?.state?.unregister?.patientName}
											alt="Image"
											style={{
												width: "32px",
												height: "32px",
												backgroundColor: UIColor.differentiationBackground.color,
												fontSize: "14px",
												color: "#101010",
												marginRight: "16px",
												marginLeft: "0",
												marginTop: "0",
												marginBottom: "0",
											}}
											className="pc_medium"
										></Avatar>
										<Text
											id="doctor_listing_select_text"
											style={{ fontSize: 16, marginRight: 8, color: UIColor.primaryText.color }}
											className={"pc_semibold"}
										>
											{this.props.location?.state?.selectedUser?.name ||
												this.props.location?.state?.unregister?.patientName}
											{/* &nbsp; &nbsp; */}
										</Text>
										<span
											id="doctor_listing_and_span"
											className="pc_regular"
											style={{
												color: "#B6B6B6",
												marginRight: 8,
												color:  UIColor.tertiaryText.color,
											}}
										>
											|
										</span>{" "}
										{/* &nbsp;&nbsp; */}
										<Text
											id="doctor_listing_Walk-in_text"
											style={{ fontSize: 14, color: UIColor.tertiaryText.color }}
											className={"pc_semibold"}
										>
											{this.props.location?.state?.unregister?.is_Unregister ? this.props.location?.state?.unregister?.age : this.state.sideInfo.age || this.state.sideInfo.age === 0
												? `${this.state.sideInfo.age} Years, `
												: this.props.location?.state?.age + " Years" ?? ""}
											{" "}{this.props.location?.state?.unregister?.is_Unregister ? this.props.location?.state?.unregister?.gender?.label : this.state.sideInfo.gender}&nbsp;&nbsp;
											{this.state?.selectedSlot?.info?.dateStart &&
											(moment()
												.startOf("day")
												.diff(
													moment(
														this.state?.selectedSlot?.info?.dateStart * 1000
													),
													"d"
												) === 0 &&
												this.state.selectedSlot?.info?.maxwalkin > 0) ===
												true &&
											!this.props.location?.state?.unregister?.is_Unregister ? (
												<span
													id="doctor_listing_Walk-in_span"
													className="pc_regular"
													style={{ color: "#F58B00" }}
												>
													{
														this.props.location?.state?.unregister?.is_Unregister || !this.state?.permissionDa?.write?.indexOf("downloadInvoice") > -1 ? "" : "Walk-in"
													}
													{/* {"Walk-in"} */}
												</span>
											) : (
												""
											)}
										</Text>
									</Div>
									<Div
										id="doctor_listing_DoctorAndAppointmentSlot_div"
										style={{ marginBottom: "8px" }}
									>
										<DoctorAndAppointmentSlot
											is_unregister={
												this.props.location?.state?.unregister?.is_Unregister
											}
											parent_id={"doctor_listing"}
											loginType={info.type}
											newBook={newBook}
											fromListing={this.state.fromListing}
											IsModifyUser={IsModifyUser}
											name={this.state.selectedSlot?.hospitalInfo?.name || ""}
											date={this.state.selectedSlot?.info?.label}
											info={this.state.selectedSlot?.info}
											hospitalInfo={this.state.selectedSlot?.hospitalInfo}
											changeState={this.changeState}
											handleMultiAppointmentChange={
												this.handleMultiAppointmentChange
											}
											multiApppointmentInfo={this.state.multiApppointmentInfo}
											isMultipleAppointment={this.state.isMultipleAppointment}
											updateMultiSlots={this.updateMultiSlots}
											oldSlotInfo={this.state.oldSlot}
											consultationMode={this.state.consultationMode}
											time={
												this.state.selectedSlot?.info?.date
													? utcTOLocal((this.state.selectedSlot?.info?.date /1000),"Do MMM YYYY")
													: ""
											}
											handleCollapse={() =>
												this.changeState(
													"slotSelected",
													!this.state.slotSelected
												)
											}
											DoctorAndAppointmentSlotCard={this.state.rightSideAllCard}
											rightSideAllCardfun={this.rightSideAllCardfun}
										/>
									</Div>
									{this.state.rightSideAllCard ? (
										<>
											<Div
												id="doctor_listing_ReasonforAppointment_div"
												style={{ marginBottom: "8px" }}
											>
												<ReasonforAppointment
													parent_id={"doctor_listing"}
													handleChange={(data) =>
														this.changeState("appointmentReason", data)
													}
													info={this.state.appointmentReason}
													options={this.props.reasonForAppointment?.data ?? []}
													title="Reason for Appointment"
													label="Add your health issues here"
													img={Reasonforappointment}
													multiple
													masterSearch
												/>
											</Div>
											{this.state.consultationMode.value !==
												"Video Consultation" && (
												<Div
													id="doctor_listing_ReasonAdditionalServices_div"
													style={{ marginBottom: "8px" }}
												>
													<ReportAndAdditionalServices
														parent_id={"doctor_listing"}
														uploadFun={this.uploadFun}
														IsModifyUser={IsModifyUser}
														changeState={this.changeState}
														patientInstruction={this.state.patientInstruction}
														upload={this.state.uploadFiles}
														is_Unregister={
															this.props.location?.state?.unregister
																?.is_Unregister
														}
														permissionData={this.state.permissionData}
													/>
												</Div>
											)}
											<Div
												id="doctor_listing_BillSummary_div"
												style={{ marginBottom: "8px" }}
											>
												<BillSummary parent_id={"doctor_listing"} />
											</Div>
											{(IsModifyUser && !newBook) && (
												<Div style={{ marginBottom: "8px" }}>
													<ReasonforAppointment
														parent_id={"doctor_listing"}
														handleChange={(data) =>
															this.changeState("modifyAppointmentReason", data)
														}
														info={this.state.modifyAppointmentReason}
														options={
															this.props?.reasonForApptCancelModify.data ?? []
														}
														title="Reason for Modification"
														label="Choose your reason"
														img={Modify_appointment}
													/>
												</Div>
											)}
											<Div
												id="doctor_listing_BillSummary_div"
												style={{ marginBottom: "8px" }}
											>
												<AppointmentInstructions parent_id={"doctor_listing"} />
											</Div>

											<>
												<Div
													id="doctor_listing_agree_instruction_div"
													style={{
														display: "flex",
														justifyContent: "center",
														marginBottom: "0px",
														width: "100%",
														marginTop: "6%",
													}}
												>
													<TermsAndConditions
														value={this.state.iagree}
														checkboxTickColor={"#fff"}
														activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
														checkboxBorderColor={UIColor.lineBorderFill.color}
														checkboxBackgroundColor={UIColor.differentiationBackground.color}
														activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
														id="doctor_listing_agree_instruction_terms"
														// value={true}
														// key={"4"}
														lable={
															<span
																id="doctor_listing_agree_instruction_span"
																style={{
																	fontSize: "12px",
																	color: "#101010",
																	marginBottom: "4px",
																	marginTop: "-10px",
																}}
																className="pc_medium"
															>
																{"I agree that I've read the instructions "}{" "}
																<span
																	id="doctor_listing_agree_instruction_star_span"
																	style={{ color: "red" }}
																>
																	*
																</span>
															</span>
														}
														inLineStyles={{ marginBottom: 16 }}
														className={classes.termsandcondition}
														onChange={() =>
															this.setState({
																...this.state,
																iagree: !this.state.iagree,
															})
														}
													/>
												</Div>
												<Div
													style={{ textAlign: "center" }}
													id="doctor_listing_confirm_div"
												>
													{this.state.iagree ? (
														<Button
															id="doctor_listing_confirm_book_button"
															name={
																!!this.props?.upsertAppointment?.loading || this.props.updateAppointment.loading || this.props?.registerUsers?.loading || this.props?.signIn?.loading || this.props?.readPerson.loading || this.props?.upsertPerson.loading || !!this.props?.checkAppointmentAgainstPatient?.loading
																	? "Booking..."
																	: "Confirm Booking"
															}
															onClick={this.handleModalOpen}
															disabled={
																!!this.props?.upsertAppointment?.loading || !!this.props?.checkAppointmentAgainstPatient?.loading
															}
															style={{
																backgroundColor: UIColor.primaryColor.color,
                                								borderColor: UIColor.primaryColor.color,
																color: "#fff",
																marginTop: "3%",
																padding: "6px 16px",
																borderRadius: "8px",
																marginBottom: "4%",
															}}
															className="pc_medium"
														/>
													) : (
														<Button
															id="doctor_listing_disabled_confirm_book_button"
															name="Confirm Booking"
															disabled={true}
															style={{
																backgroundColor: UIColor.lineBorderFill.color,
																borderColor: UIColor.lineBorderFill.color,
																color: UIColor.tertiaryText.color,
																marginTop: "3%",
																padding: "6px 16px",
																borderRadius: "8px",
																marginBottom: "4%",
															}}
															className="pc_medium"
														/>
													)}
												</Div>
											</>
										</>
									) : null}
								</Div>
							</div>
							<SideMenu
								id="doctor_listing_filter_sidemenu"
								direction="right"
								width={405}
								color="default"
								onClose={() => this.mainfilter()}
								inLineStyles={{ padding: 15 }}
								open={this.state.filter}
							>
								{this.state.filter && (
									<FilterDrawer
										parent_id={"doctor_listing"}
										allSpeciality={allSpeciality}
                    selectedSpec={this?.props?.location?.state?.specialities}
										filterData={filterData}
										ApplyFilter={this.ApplyFilter}
										resetFilter={() => {
                      if (filterData && Object.keys(filterData).length > 0) {
                        this.changeState("data", []);
                        const {
                          hospital = [],
                          specialities = [],
                          location = {},
                          doctors = [],
                          /*symptoms, location*/
                        } = this?.props?.location?.state;
                        this.props.DOCTER_LISTING({
                          hospital: hospital?.map((l) => l?.value),
                          specialities: specialities.map((spec) => spec.value),
                          latitude: location?.latitude || 0,
                          longtitude: location?.longitude || 0,
                          date: undefined,
                          doctorId: doctors?.[0]?.value,
                        }).then(d => {
                          this.changeState("data", d?.payload?.data || []);
                          this.changeState("filterData", {})
                          this.changeState("applyedDate", moment().format("MM-DD-YYYY"))
                        });
                      } else {
                        this.context.setSnack({
                          open: true,
                          severity: AlertProps.severity.error,
                          msg: 'No Filter Applied',
                          vertical: AlertProps.vertical.top,
                          horizontal: AlertProps.horizontal.right,
                          tone: true,
                        })
                      }
                    }}
										onClose={this.mainfilter}
                    allLang={this.state.allLang}
									/>
								)}
							</SideMenu>
							<SideMenu
								id="doctor_listing_sidemenu"
								open={this.state.sidebar}
								direction="right"
								width={435}
								color="default"
								onClose={() => this.toggleSideBar(false)}
								inLineStyles={{ padding: "16px 24px" }}
								// open={true}
							>
								<text
									id="doctor_listing_Guest_user_text"
									className={classes.guestTitle}
								>
									{this.state.appointmentUpdate
										? (
											this.props?.location?.state?.newBook
											? "Book Again"
											: "Update Appointment"
										) : this.props.location?.state?.unregister?.is_Unregister || this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1
										? "Appointment Booking confirmation"
										: "Guest user confirmation"}
								</text>

								<Divider
									id="doctor_listing_Guest_user_divider"
									key={"2"}
									className=""
									label=""
									borderColor=""
									textColor=""
									orientation="horizontal"
									inLineStyles={{ margin: "16px 0px" }}
									// variant="middle"
									// style={{ height: "24px" }}
								/>

								{this.state.showOtpVerification ? (
									<Div id="doctor_listing_bookby_div">
										{this.props?.guestInfo?.data?.type === 0 ? null : (
											<>
												{(!this.props.location?.state?.unregister?.is_Unregister && !(this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ) ? (
													<Div
														id="doctor_listing_bookfor_div"
														style={{
															border: `1px solid ${UIColor.lineBorderFill.color}`,
															marginBottom: 10,
															borderRadius: 8,
															padding: 16,
															display: this.state.appointmentUpdate
																? "none"
																: "block",
														}}
													>
														<H6
															id="doctor_listing_bookfor_h6"
															inLineStyles={{
																fontSize: 16,
																color:  UIColor.primaryText.color,
																letterSpacing: 0,
																lineHeight: 1,
															}}
															className="pc_medium"
														>
															BOOKING FOR
															<Text
																id="doctor_listing_bookfor_text"
																inLineStyles={{
																	color: UIColor.secondaryColor.color,
																	fontSize: 16,
																	letterSpacing: 0,
																}}
																className="pc_medium"
															>
																{" | "}
																{this.state.guestInfo.name}
															</Text>
														</H6>
													</Div>
												) : (
													""
												)}{" "}
											</>
										)}
										{(!this.props.location?.state?.unregister?.is_Unregister || !this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ?? (
											<>
												{(!this.props.location?.state?.unregister
													?.is_Unregister || !this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? (
													<Div
														id="doctor_listing_book_by_div"
														style={{
															border: `1px solid ${UIColor.lineBorderFill.color}`,
															display: this.state.appointmentUpdate
																? "none"
																: "block",
															marginBottom: 20,
															borderRadius: 8,
															padding: 16,
														}}
													>
														<H6
															id="doctor_listing_book_by_h6"
															inLineStyles={{
																fontSize: 16,
																color: UIColor.primaryText.color,
																letterSpacing: 0,
																lineHeight: 1,
															}}
															className="pc_medium"
														>
															BOOKING BY
															<Text
																id="doctor_listing_book_by_text"
																inLineStyles={{
																	color: UIColor.secondaryColor.color,
																	fontSize: 16,
																	letterSpacing: 0,
																}}
																className="pc_medium"
															>
																{" | "}
																{this.state.guestBookerInfo.name}
															</Text>
														</H6>
													</Div>
												) : (
													""
												)}
											</>
										)}
										{/* {JSON.stringify(this.state.selectedSlot)} */}
										{(this.props.location?.state?.unregister?.is_Unregister ===
										true  || this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? (
											<>
												<Grid container spacing={2}>
													<Grid item xs={6}>
														<h6
															style={{
																marginBottom: "7px",
																marginLeft: "15px",
																color: "#B6B6B6",
															}}
														>
															{"DOCTOR INFO"}
														</h6>
														<Div
															id={`doctorcard_img_sub_div`}
															key={"0"}
															inLineStyles=""
															className="qdm"
															style={{
																display: "flex",
																justifyContent: "center",
																alignItems: "flex-start",
																marginBottom: 16,
															}}
														>
															<Avatar
																id={`doctorcard_image`}
																key={"0"}
																variant="circle"
																letter="Avatar"
																src="https://worthingtonmotorcycles.com.au/wp-content/uploads/2017/06/tlc-perth-user-icon-im2.png"
																alt="Image"
																style={{
																	margin: "0px",
																	width: 40,
																	height: 40,
																	backgroundColor: "#6F6F6F",
																}}
															></Avatar>

															<Div
																id={`doctorcard_title_div`}
																key={"1"}
																style={{
																	marginLeft: 16,
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<Text
																	id={`doctorcard_text`}
																	style={{
																		fontSize: 16,
																		color: "#101010",
																		letterSpacing: 0,
																	}}
																	key={"0"}
																	name={
																		this.state.selectedSlot?.hospitalInfo
																			?.name || ""
																	}
																	className={"pc_medium"}
																></Text>
																{/* {JSON.stringify(this.state.selectedSlot)} */}
																{/* <br /> */}
																{/* {data?.subtext && ( */}
																<Text
																	id={`doctorcard_text`}
																	key={"1"}
																	name={
																		this.state.selectedSlot?.hospitalInfo
																			?.title ||
																		this.state.selectedSlot?.hospitalInfo
																			?.hospitalLocation
																	}
																	style={{
																		fontSize: "12px",
																		marginTop: 4,
																		color: "#101010",
																		// : "#6f6f6f",
																	}}
																	className={"pc_regular"}
																></Text>
															</Div>
														</Div>
													</Grid>
													<Grid item xs={6}>
														<h6
															style={{
																marginBottom: "7px",
																marginLeft: "15px",
																color: "#B6B6B6",
															}}
														>
															{"DATE & TIME"}
														</h6>
														<Div
															id={`doctorcard_img_sub_div`}
															key={"0"}
															inLineStyles=""
															className="qdm"
															style={{
																display: "flex",
																// justifyContent: "center",
																alignItems: "center",
																marginBottom: 16,
																marginLeft: "16px",
															}}
														>
															<img
																id="doctor_listing_dob_calender_img"
																alt="loding"
																src="/images/icons8-calendar.svg"
																style={{
																	backgroundColor: "",
																	color: "",
																	cursor: "pointer",
																	borderLeft: "1px solid #E0E0E0",
																	paddingLeft: "10px",
																}}
															/>

															<Div
																id={`doctorcard_title_div`}
																key={"1"}
																style={{
																	marginLeft: 16,
																	display: "flex",
																	flexDirection: "column",
																}}
															>
																<Text
																	id={`doctorcard_text`}
																	style={{
																		fontSize: 16,
																		color: "#101010",
																		letterSpacing: 0,
																	}}
																	key={"0"}
																	name={
																		this.state.selectedSlot?.info?.label || ""
																	}
																	className={"pc_medium"}
																></Text>
																{/* <br /> */}
																{/* {data?.subtext && ( */}
																{/* <Text
                                  id={`doctorcard_text`}
                                  key={"1"}
                                  name={this.state.selectedSlot?.hospitalInfo?.title || this.state.selectedSlot?.hospitalInfo?.hospitalLocation}
                                  // name={"data?.subtext"}
                                  style={{
                                    fontSize: "12px",
                                    marginTop: 4,
                                    color: "#101010",
                                    // : "#6f6f6f",
                                  }}
                                  className={"pc_regular"}
                                ></Text> */}
															</Div>
														</Div>
													</Grid>
												</Grid>
											</>
										) : null}
										{(this.props.location?.state?.unregister?.is_Unregister ===
										true || this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ? (
											""
										) : (
											<Div
												id="doctor_listing_book_by_div"
												style={{
													border: "1px solid #E0E0E0",
													display: this.state.appointmentUpdate
														? "none"
														: "block",
													marginBottom: 20,
													borderRadius: 8,
													padding: 16,
												}}
											>
												<H6
													id="doctor_listing_book_by_h6"
													inLineStyles={{
														fontSize: 16,
														color: "#000000",
														letterSpacing: 0,
														lineHeight: 1,
													}}
													className="pc_medium"
												>
													BOOKING BY
													<Text
														id="doctor_listing_book_by_text"
														inLineStyles={{
															color: "#F58B00",
															fontSize: 16,
															letterSpacing: 0,
														}}
														className="pc_medium"
													>
														{" | "}
														{this.state.guestBookerInfo.name}
													</Text>
												</H6>
											</Div>
										)}
										<Div
											id="doctor_listing_verification_div"
											inLineStyles={{
												backgroundColor: "#F5FAFF",
												padding: 30,
												borderRadius: 8,
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												paddingBottom: 0,
											}}
										>
											<H6
												id="doctor_listing_otp_verification_h6"
												inLineStyles={{
													marginBottom: 24,
													fontSize: 16,
													letterSpacing: 0,
												}}
												className="pc_medium"
											>
												OTP Verification
											</H6>
											<Text
												id="doctor_listing_otp_sent_text"
												className="pc_regular"
												inLineStyles={{
													fontSize: 14,
													textAlign: "center",
													letterSpacing: 0,
													color: "#6F6F6F",
												}}
											>
												Please enter the OTP sent to{" "}
												<Text
													id="doctor_listing_otp_number_text"
													inLineStyles={{
														color: "#101010",
														letterSpacing: 0,
														fontSize: 14,
													}}
													className="pc_medium"
												>
													{this.state.appointmentUpdate
														? this.state?.otpNumber ||
														  this.props.location?.state?.unregister?.mobileNo
																?.mobileNo
														: (!this.props.location?.state?.unregister?.is_Unregister && this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1) ?
														this.props.location?.state?.userNo : this.state.guestBookerInfo?.mobileNo?.mobileNo ||
														  this.props.location?.state?.unregister?.mobileNo
																?.mobileNo}
												</Text>
												<Link
													id="doctor_listing_goback_link"
													color="textPrimary"
													underLine="none"
													inLineStyles={{
														color: "#0071F2",
														letterSpacing: 0,
														display: this.state.appointmentUpdate
															? "none"
															: "unset",
														textDecoration: "none",
														fontSize: 14,
													}}
													className="pc_medium"
													onClick={() => {
														this.setState(
                              {
                                meageget: {},
                              },
                              () => {
                                this.goBack();
                              }
                            );
													}}
													name={
														(this.props.location?.state?.unregister
															?.is_Unregister || this.state?.permissionData?.write?.indexOf("appointmentBookingConfirmation") > -1)
															? ""
															: "Change"
													}
												/>
											</Text>
											<Div
												id="doctor_listing_otpinput_div"
												inLineStyles={{ marginBottom: 40, marginTop: 30 }}
											>
												<OtpInput
													id="doctor_listing_otpinput"
													numberOfInputs={6}
													variant="standard"
													type="text"
													focusTextColor="#0071F2"
													focusBorderColor="#0071F2"
													inLineStyles={{ backgroundColor: "transparent" }}
													onChange={(e) => this.changeState("otp", e)}
													// style={ { display : "flex",justifyContent : "center",alignItems : "center",paddingLeft : "" } }
												/>
											</Div>
											<Text
												id="doctor_listing_receive_OTP_text"
												inLineStyles={{
													color: "#6F6F6F",
													fontSize: 12,
													letterSpacing: 0,
												}}
												className="pc_regular"
											>
												Didn't receive OTP ?{" "}
												<Link
													id="doctor_listing_Resend_OTP_link"
													color="textPrimary"
													underLine="none"
													inLineStyles={{
														color: "#101010",
														letterSpacing: 0,
														fontSize: 12,
													}}
													onClick={() => this.handleresendOTP()}
													className="pc_medium"
													name={"Resend OTP"}
												/>
											</Text>
											{this.state.meageget?.payload?.data?.message ? (
												<text
													id="doctor_listing_message_text"
													style={{
														display: "flex",
														justifyContent: "center",
														fontSize: "12px",
														marginBottom: "6px",
														marginTop: "6px",
														color: "#38C20A",
														textAlign: "center",
													}}
												>
													{this.state.meageget?.payload.data?.message}
												</text>
											) : null}

											<Div
												id="doctor_listing_book_div"
												style={{
													display: "flex",
													alignItems: "center",
													marginTop: 24,
												}}
											></Div>
										</Div>
										<Div
											id="doctor_listing_button_div"
											style={{ textAlign: "center", marginTop: "24px" }}
										>
											<Button
												id="doctor_listing_confirm_button"
												// key={"7"}
												onClick={() => this.changeScreen()}
												type="button"
												variant="outlined"
												name={
													this.props.upsertAppointment.loading ||
													this.props.updateAppointment.loading ||
													this.props.readPerson.loading ||
													this.props.upsertPerson.loading ||
													this.props.addRelatedPerson.loading ||
													this.props.signIn.loading ||
													this.props?.checkAppointmentAgainstPatient?.loading
														? "Updating..."
														: "Confirm"
												}
												style={{
													backgroundColor: UIColor?.primaryColor?.color,
													borderColor: UIColor?.primaryColor?.color,
													borderRadius: "8px",
													padding: "10px 15px",
													fontSize: "14px",
													height: "40px",
												}}
												disabled={
													this.props.upsertAppointment.loading ||
													this.props.updateAppointment.loading ||
													this.props.readPerson.loading ||
													this.props.upsertPerson.loading ||
													this.props.addRelatedPerson.loading ||
													this.props.signIn.loading ||
													this.props?.checkAppointmentAgainstPatient?.loading
												}
												className="pc_medium"
											></Button>
										</Div>
									</Div>
								) : (
									<Div id="doctor_listing_guest_parent_div">
										<Card
											id="doctor_listing_guest_card"
											style={{ marginBottom: "8px", borderRadius: 8 }}
										>
											<CardActions
												id="doctor_listing_guest_cardactions"
												onClick={() => this.handleOpen(0)}
												disableSpacing
												style={{
													padding: 0,
													paddingLeft: 16,
													paddingBottom: 0,
													cursor: "pointer",
												}}
											>
												<Text
													id="doctor_listing_guestinfo_text"
													key={"0"}
													name={
														this.props?.guestInfo?.data?.type === 0
															? "BOOKING BY"
															: "BOOKING FOR"
													}
													style={{
														fontSize: "16px",
														letterSpacing: 0,
														lineHeight: 1,
														color: "#000000",
													}}
													className="pc_medium"
												/>

												<IconButton
													id="doctor_listing_guest_show_more_iconbutton"
													className={clsx(classes.expand, {
														[classes.expandOpen]:
															this.state.collpaseOpen === 0 ? true : false,
													})}
													aria-expanded={
														this.state.collpaseOpen === 0 ? true : false
													}
													aria-label="show more"
												>
													<ExpandMoreIcon id="doctor_listing_guest_show_more_iconbuttonexpandmoreicon" />
												</IconButton>
											</CardActions>
											<Collapse
												id="doctor_listing_collapse"
												className={classes.body}
												in={this.state.collpaseOpen === 0 ? true : false}
												// open={this.state.collpaseOpen === 0 ? true : false}
												timeout="auto"
												unmountOnExit
											>
												<Div
													id="doctor_listing_patient_label_div"
													className={classes.label}
												>
													<TextInput
														id="doctor_listing_patient_name_label_textinput"
														// key={"0"}
														placeholder=""
														type="text"
														name="text"
														variant="outlined"
														helperTextType="error"
														label={
															<H6
																id="doctor_listing_patient_name_label_span"
																className="pc_regular"
																style={{
																	fontSize: "12px",
																	color: "#6f6f6f",
																	margin: 0,
																	marginBottom: "8px",
																	letterSpacing: 0,
																	lineHeight: 1,
																}}
															>
																Patient name{" "}
																<span
																	id="doctor_listing_patient_name_star_span"
																	style={{ color: "red" }}
																>
																	*
																</span>
															</H6>
														}
														autoFocus={false}
														autoComplete="off"
														search={false}
														borderColor="#9b9b9b"
														padding="10"
														margin="0"
														elevation="0"
														maxLength={30}
														value={this.state.guestInfo.name}
														onChange={(e) =>
															this.changeGuestInfo(
																"guestInfo",
																"name",
																e.target.value
															)
														}
														className={classes.textField}
													/>
													{(this.props?.guestInfo?.data?.type === 0
														? this.state.error?.guestBookerInfo?.name
														: this.state?.error?.guestInfo?.name) && (
														<div id="doctor_listing_patient_name_error_div">
															<Text
																id="doctor_listing_patient_name_error_text"
																style={{ color: "#FF4D4A", fontSize: 14 }}
																className="pc_regular"
															>
																{"Please Enter Name"}
															</Text>
														</div>
													)}
												</Div>
												<span
													id="doctor_listing_dob_span"
													style={{
														fontSize: "12px",
														color: "#6f6f6f",
														marginBottom: "4px",
														marginTop: "-10px",
														fontWeight: "500",
													}}
												>
													DOB{" "}
													<span
														id="doctor_listing_dob_star_span"
														style={{ color: "red" }}
													>
														*
													</span>
												</span>
												<Div
													id="doctor_listing_dob_calender_div"
													style={{
														border: "1px solid #E0E0E0",
														padding: "10px",
														height: "40px",
														width: "100%",
														fontSize: "14px",
														borderRadius: "4px",
														background: "transparent",
														display: "flex",
														justifyContent: "space-between",
														alignItems: "center",
														position: "relative",
														marginBottom: "14px",
													}}
												>
													<Text id="doctor_listing_dob_calender_text">
														{this.state.age?.val}
													</Text>
													<img
														id="doctor_listing_dob_calender_img"
														alt="loding"
														src="/images/icons8-calendar.svg"
														onClick={(e) => this.handlepapover(e)}
														style={{
															backgroundColor: "",
															color: "",
															cursor: "pointer",
															borderLeft: "1px solid #E0E0E0",
															paddingLeft: "10px",
														}}
													/>
													{/* <Icons
                        key={'0'}
                        fontIcon='calendar'
                        ariaHidden='true'
                        className=''
                        size='small'
                        style={{
                          backgroundColor: '',
                          color: '',
                          cursor: 'pointer',
                          borderLeft: '1px solid #E0E0E0',
                          paddingLeft: '10px'
                        }}
                        onClick={e => this.handlepapover(e)}
                      ></Icons> */}
													<Popover
														style={{
															transform: "unset",
															position: "absolute",
															width: "320px",
															top: "30%",
															zIndex: "10",
														}}
														open={this.state.opencalendar}
														anchorOrigin={{
															vertical: "bottom",
															horizontal: "left",
														}}
														onClose={() => this.handlepapoverclose()}
													>
														<Calendars
															id="doctor_listing_dob_calenders"
															maxDate={new Date()}
															allowPartialRange
															onClickMonth={(value, event) => {
																this.handlepapoverclose(false);
															}}
															onClickYear={(value, event) => {
																this.handlepapoverclose(false);
															}}
															onClickWeekNumber={(value, event) => {
																this.handlepapoverclose(false);
															}}
															className={classes.monthCalendar}
															onChange={(value, event) => {
																const milli = value.getTime();
																const oldVal = this.state.age.milli;
																const oldDate = moment(oldVal).format("DD");
																const newDate = moment(milli).format("DD");
																const date = {
																	val: moment(value).format("DD-MM-YYYY"),
																	milli,
																};
																this.changeState("age", date);
																if (oldDate !== newDate || milli === oldVal) {
																	this.handlepapoverclose(true);
																}
															}}
															uptoCurrentYear
														/>
													</Popover>
												</Div>
												{(this.props?.guestInfo?.data?.type === 0
													? this.state.error?.guestBookerInfo?.dob
													: this.state?.error?.guestInfo?.dob) && (
													<div id="doctor_listing_dob_error_div">
														<Text
															id="doctor_listing_dob_error_text"
															style={{ color: "#FF4D4A", fontSize: 14 }}
														>
															{"Please Select DOB"}
														</Text>
													</div>
												)}
												<Div
													id="doctor_listing_gender_div"
													className={classes.label}
												>
													<TapSelect
														id="doctor_listing_gender_tapselect"
														// key={"2"}
														type="single"
														fullWidth={false}
														selectedBorderColor="#0071F2"
														selectedBackgroundColor="#0071F2"
														selectedTextColor="#ffffff"
														textSize="14"
														iconHeight="20"
														iconWidth="20"
														label={
															<H6
																id="doctor_listing_gender_span"
																style={{
																	fontSize: "12px",
																	color: "#6f6f6f",
																	margin: 0,
																	letterSpacing: 0,
																	lineHeight: 1,
																}}
																className="pc_regular"
															>
																Gender{" "}
																<span
																	id="doctor_listing_gender_star_span"
																	style={{ color: "red" }}
																>
																	*
																</span>
															</H6>
														}
														options={this.genderOptions}
														defaultSelected={this.state.guestInfo.gender}
														onChange={(e) =>
															this.changeGuestInfo(
																"guestInfo",
																"gender",
																this.state?.guestInfo?.gender?.value ===
																	e?.value
																	? {}
																	: e
															)
														}
														// inLineStyles={{
														//   margin: 0,
														//   marginBottom: 6,
														//   padding: "4px 12px !important",
														//   marginRight: 6,
														// }}
														className={classes.tapSelect}
													/>
													{(this.props?.guestInfo?.data?.type === 0
														? this.state.error?.guestBookerInfo?.gender
														: this.state?.error?.guestInfo?.gender) && (
														<div id="doctor_listing_gender_error_div">
															<Text
																id="doctor_listing_gender_error_text"
																style={{ color: "#FF4D4A", fontSize: 14 }}
															>
																{"Please Select Gender"}
															</Text>
														</div>
													)}
												</Div>
												<H6
													id="doctor_listing_Mobile_number_span"
													style={{
														fontSize: "12px",
														color: "#6f6f6f",
														margin: 0,
														letterSpacing: 0,
														lineHeight: 1,
														marginBottom: 8,
													}}
													className="pc_regular"
												>
													Mobile number
													<span
														id="doctor_listing_Mobile_number_guestinfo_span"
														style={{ color: "red" }}
													>
														{this.props?.guestInfo?.data?.type === 0 ? "*" : ""}
													</span>
												</H6>
												<div className={classes.mobile}>
													<MobileNumberWithCode
														id="doctor_listing_Mobile_number_mobilenumberwithcode"
														// key={"3"}
														type="number"
														label="Phone number"
														borderColor="#CACACA"
														borderRadius="6"
														disabled={false}
														maxLength={30}
														value={this.state.guestInfo.mobileNo.mobileNo}
														dropdown={this.state.guestInfo.mobileNo.countryData}
														// dropdownData={[
														// 	{
														// 		name: "India",
														// 		flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
														// 		altSpellings: "IND",
														// 		callingCodes: "91",
														// 	},
														// ]}
														dropdownData={this?.props?.CountryCode?.data}
														onChange={(e) =>
															this.changeGuestInfo("guestInfo", "mobileNo", e)
														}
														// style={ { marginTop : "24px",paddingTop : "" } }
														// inLineStyles={{
														//   marginBottom:
														//     this.state.error?.guestBookerInfo?.callingCodes ||
														//       this.state.error?.guestBookerInfo?.mobileNo
														//       ? 0
														//       : 16,
														// }}
													/>
													{this.props?.guestInfo?.data?.type === 0 &&
														(this.state.error?.guestBookerInfo?.mobileNo ||
															this.state.error?.guestBookerInfo
																?.callingCodes) && (
															<Div
																id="doctor_listing_Mobile_number_error_div"
																style={{ marginBottom: 10 }}
															>
																{this.state.error?.guestBookerInfo
																	?.callingCodes && (
																	<Text
																		id="doctor_listing_country_code_error_text"
																		style={{ color: "#FF4D4A", fontSize: 14 }}
																		className="pc_regular"
																	>
																		{"Please select the country code"}
																	</Text>
																)}
																{!this.state.error?.guestBookerInfo
																	?.callingCodes &&
																	this.state.error?.guestBookerInfo
																		?.mobileNo && (
																		<Text
																			id="doctor_listing_Mobile_number_error_text"
																			style={{ color: "#FF4D4A", fontSize: 14 }}
																			className="pc_regular"
																		>
																			{"Please enter valid mobile number"}
																		</Text>
																	)}
															</Div>
														)}

													{this.props?.guestInfo?.data?.type === 1 &&
														(this.state.error?.guestInfo?.mobileNo ||
															this.state.error?.guestInfo?.callingCodes) && (
															<Div
																id="doctor_listing_guestinfo_error_div"
																style={{ marginBottom: 10 }}
															>
																{this.state.error?.guestInfo?.callingCodes && (
																	<Text
																		id="doctor_listing_country_code_text"
																		style={{ color: "#FF4D4A", fontSize: 14 }}
																	>
																		{"Please select the country code"}
																	</Text>
																)}
																{!this.state.error?.guestInfo?.callingCodes &&
																	this.state.error?.guestInfo?.mobileNo && (
																		<Text
																			id="doctor_listing_Mobile_number_text"
																			style={{ color: "#FF4D4A", fontSize: 14 }}
																		>
																			{"Please enter the mobile number"}
																		</Text>
																	)}
															</Div>
														)}
												</div>

												<TermsAndConditions
													id="doctor_listing_send_appointment_terms"
													value={true}
													checkboxSize={14}
													checkboxTickColor={"#fff"}
													activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
													checkboxBorderColor={UIColor.lineBorderFill.color}
													checkboxBackgroundColor={UIColor.differentiationBackground.color}
													activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
													disabled
													// key={"4"}
													onChange={(value) => console.log(value)}
													lable={
														<H6
															id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
															style={{
																fontSize: "12px",
																color: "#6F6F6F",
																margin: 0,
																letterSpacing: 0,
															}}
															className={"pc_regular"}
														>
															Send appointment update and notification to this
															mobile number
														</H6>
													}
													inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
													className={classes.termsAndConditions}
												/>
												<Div
													id="doctor_listing_mail_id_div"
													className={classes.label}
												>
													<TextInput
														id="doctor_listing_mail_id_text"
														// key={"5"}
														placeholder=""
														type="text"
														name="text"
														variant="outlined"
														helperTextType="error"
														label={
															<H6
																id="doctor_listing_sidemenu_mail_id_span"
																style={{
																	fontSize: "12px",
																	color: "#6f6f6f",
																	margin: 0,
																	letterSpacing: 0,
																	lineHeight: 1,
																	marginBottom: 8,
																}}
															>
																Mail Id
																<span style={{ color: "red" }}></span>
															</H6>
														}
														autoFocus={false}
														autoComplete="off"
														search={false}
														borderColor="#9b9b9b"
														padding="10"
														margin="0"
														elevation="0"
														value={this.state.guestInfo.mailId}
														onChange={(e) =>
															this.changeGuestInfo(
																"guestInfo",
																"mailId",
																e.target.value
															)
														}
														className={classes.textField}
														// style={{ marginBottom: '14px', marginTop: '2%' }}
													/>
													{(this.props?.guestInfo?.data?.type === 0
														? this.state.error?.guestBookerInfo?.mailId
														: this.state?.error?.guestInfo?.mailId) && (
														<div id="doctor_listing_mail_id_error_div">
															<Text
																id="doctor_listing_mail_id_error_text"
																style={{ color: "#FF4D4A", fontSize: 14 }}
															>
																{"Please Enter Valid Email ID"}
															</Text>
														</div>
													)}
												</Div>
												<TermsAndConditions
													id="doctor_listing_Send_appointment_termsandcondition"
													onChange={(value) => console.log(value)}
													checkboxTickColor={"#fff"}
													activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
													checkboxBorderColor={UIColor.lineBorderFill.color}
													checkboxBackgroundColor={UIColor.differentiationBackground.color}
													activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
													disabled
													checkboxSize={14}
													lable={
														<H6
															id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
															style={{
																fontSize: "12px",
																color: "#6F6F6F",
																margin: 0,
																letterSpacing: 0,
															}}
															className={"pc_regular"}
														>
															Send appointment update and notification to this
															mail id
														</H6>
													}
													inLineStyles={{ lineHeight: 1 }}
													className={classes.termsAndConditions}
												/>

												<Button
													id="doctor_listing_Send_OTP_Confirm_button"
													// key={"7"}
													onClick={() => {
														this.props?.guestInfo?.data?.type === 0
															? this.handleMySelfSignUp()
															: this.checkValidationsForOthers();
													}}
													type="button"
													variant="outlined"
													name={
														this.props?.guestInfo?.data?.type === 0
															? this.props.generateOTP?.loading ||
															  this.props.registerUsers?.loading
																? "Sending Otp..."
																: "Send OTP & Confirm"
															: "Next"
													}
													style={{
														margin: "auto",
														backgroundColor: "#0071F2",
														borderColor: "#0071F2",
														borderRadius: "8px",
														display: "flex",
														justifyContent: "center",
														padding: "10px 15px",
														fontSize: "14px",
														marginTop: 32,
														height: 40,
													}}
													className="pc_medium"
												></Button>
											</Collapse>
										</Card>
										{this.props?.guestInfo?.data?.type === 0 ? null : (
											<Card id="doctor_listing_guest_card">
												<CardActions
													id="doctor_listing_guest_cardactions"
													onClick={() => this.checkValidationsForOthers()}
													disableSpacing
													style={{
														padding: 0,
														paddingLeft: 16,
														paddingBottom: 0,
														cursor: "pointer",
													}}
												>
													<Text
														id="doctor_listing_guest_bookingby_text"
														key={"0"}
														name="BOOKING BY"
														style={{
															fontSize: "16px",
															letterSpacing: 0,
															lineHeight: 1,
															color: "#000000",
														}}
														className="pc_medium"
													/>

													<IconButton
														id="doctor_listing_guest_showmore_iconbutton"
														className={clsx(classes.expand, {
															[classes.expandOpen]:
																this.state.collpaseOpen === 1 ? true : false,
														})}
														aria-expanded={
															this.state.collpaseOpen === 1 ? true : false
														}
														aria-label="show more"
													>
														<ExpandMoreIcon id="doctor_listing_guest_expandmoreicon" />
													</IconButton>
												</CardActions>
												<Collapse
													id="doctor_listing_guest_collapse"
													className={classes.body}
													in={this.state.collpaseOpen === 1 ? true : false}
													// open={this.state.collpaseOpen === 0 ? true : false}
													timeout="auto"
													unmountOnExit
												>
													<Div
														id="doctor_listing_guest_your_name_div"
														className={classes.label}
													>
														<TextInput
															id="doctor_listing_guest_your_name_textinput"
															// key={"0"}
															placeholder=""
															type="text"
															name="text"
															variant="outlined"
															helperTextType="error"
															label={
																<H6
																	id="doctor_listing_guest_your_name_span"
																	className="pc_regular"
																	style={{
																		fontSize: "12px",
																		color: "#6f6f6f",
																		margin: 0,
																		marginBottom: "8px",
																		letterSpacing: 0,
																		lineHeight: 1,
																	}}
																>
																	What's your name ?
																	<span
																		id="doctor_listing_guest_your_star_span"
																		style={{ color: "red" }}
																	>
																		{" "}
																		*
																	</span>
																</H6>
															}
															autoFocus={false}
															autoComplete="off"
															search={false}
															borderColor="#9b9b9b"
															padding="10"
															margin="0"
															elevation="0"
															value={this.state.guestBookerInfo.name}
															onChange={(e) =>
																this.changeGuestInfo(
																	"guestBookerInfo",
																	"name",
																	e.target.value
																)
															}
															className={classes.textField}
														/>
														{this.state.error?.guestBookerInfo?.name && (
															<div
																id="doctor_listing_guest_your_error_div"
																className={"pc_regular"}
															>
																<Text
																	id="doctor_listing_guest_your_error_text"
																	className="pc_regular"
																	style={{ color: "#FF4D4A", fontSize: 14 }}
																>
																	{"Please Enter Name"}
																</Text>
															</div>
														)}
													</Div>
													<Div
														id="doctor_listing_guest_relationship_div"
														className={classes.label}
													>
														<TapSelect
															id="doctor_listing_guest_relationship_tapselect"
															// key={"2"}
															type="single"
															fullWidth={false}
															selectedBorderColor="#0071F2"
															selectedBackgroundColor="#0071F2"
															selectedTextColor="#ffffff"
															textSize="16"
															iconHeight="20"
															iconWidth="20"
															textColor="#000"
															label={
																<H6
																	id="doctor_listing_guest_relationship_span"
																	style={{
																		fontSize: "12px",
																		color: "#6f6f6f",
																		margin: 0,
																		letterSpacing: 0,
																		lineHeight: 1,
																	}}
																	className="pc_regular"
																>
																	What's your relationship with{" "}
																	{this.state.guestInfo.name} ?
																	<span
																		id="doctor_listing_guest_relationship_star_span"
																		style={{ color: "red" }}
																	>
																		{" "}
																		*
																	</span>
																</H6>
															}
															options={this.relationshipOptions}
															defaultSelected={{
																value: this.state.guestBookerInfo.relationship,
																label: this.state.guestBookerInfo.relationship,
															}}
															onChange={(e) =>
																this.changeGuestInfo(
																	"guestBookerInfo",
																	"relationship",
																	this.state?.guestBookerInfo?.relationship
																		?.value === e?.value
																		? {}
																		: e.value
																)
															}
															// inLineStyles={{
															//   padding: "4px 12px !important",
															//   margin: 6,
															// }}
															className={classes.tapSelect}
														/>
														{this.state.error?.guestBookerInfo
															?.relationship && (
															<div
																id="doctor_listing_guest_relationship_error_div"
																className={"pc_regular"}
															>
																<Text
																	id="doctor_listing_guest_relationship_error_text"
																	style={{ color: "#FF4D4A", fontSize: 14 }}
																>
																	{"Please Select Relationship"}
																</Text>
															</div>
														)}
													</Div>
													<H6
														id="doctor_listing_guest_phone_number_span"
														style={{
															fontSize: "12px",
															color: "#6f6f6f",
															margin: 0,
															letterSpacing: 0,
															lineHeight: 1,
															marginBottom: 8,
														}}
														className="pc_regular"
													>
														Enter your phone number
														<span
															id="doctor_listing_guest_phone_number_star_span"
															style={{ color: "red" }}
														>
															{" *"}
														</span>
													</H6>
													<div className={classes.mobile}>
														<MobileNumberWithCode
															id="doctor_listing_guest_mobilenumberwithcode"
															// key={"3"}
															type="number"
															borderColor="#CACACA"
															borderRadius="6"
															disabled={false}
															maxLength={30}
															value={
																this.state.guestBookerInfo.mobileNo.mobileNo
															}
															dropdown={
																this.state.guestBookerInfo.mobileNo.countryData
															}
															// dropdownData={[
															// 	{
															// 		name: "India",
															// 		flag: "https://upload.wikimedia.org/wikipedia/en/4/41/Flag_of_India.svg",
															// 		altSpellings: "IND",
															// 		callingCodes: "91",
															// 	},
															// ]}
															dropdownData={this?.props?.CountryCode?.data}
															onChange={(e) =>
																this.changeGuestInfo(
																	"guestBookerInfo",
																	"mobileNo",
																	e
																)
															}
															// style={ { marginTop : "24px",paddingTop : "" } }
															// inLineStyles={{
															//   marginBottom:
															//     this.state.error?.guestBookerInfo
															//       ?.callingCodes ||
															//       this.state.error?.guestBookerInfo?.mobileNo
															//       ? 0
															//       : 16,
															// }}
														/>
														{(this.state.error?.guestBookerInfo?.mobileNo ||
															this.state.error?.guestBookerInfo
																?.callingCodes) && (
															<Div
																id="doctor_listing_guestbookerinfo_div"
																className={"pc_regular"}
																style={{ marginBottom: 10 }}
															>
																{this.state.error?.guestBookerInfo
																	?.callingCodes && (
																	<Text
																		id="doctor_listing_guestbookerinfo_country_error_text"
																		style={{ color: "#FF4D4A", fontSize: 14 }}
																	>
																		{"Please select the country code"}
																	</Text>
																)}
																{!this.state.error?.guestBookerInfo
																	?.callingCodes &&
																	this.state.error?.guestBookerInfo
																		?.mobileNo && (
																		<Text
																			id="doctor_listing_guestbookerinfo_country_error_text"
																			style={{ color: "#FF4D4A", fontSize: 14 }}
																		>
																			{"Please enter the mobile number"}
																		</Text>
																	)}
															</Div>
														)}
													</div>
													<TermsAndConditions
														id="doctor_listing_guestbookerinfo_send_terms"
														checkboxSize={14}
														value={this.state.appointmentUpdate}
														checkboxTickColor={"#fff"}
														activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
														checkboxBorderColor={UIColor.lineBorderFill.color}
														checkboxBackgroundColor={UIColor.differentiationBackground.color}
														activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
														// key={"4"}
														lable={
															<H6
																id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
																style={{
																	fontSize: "12px",
																	color: "#6F6F6F",
																	margin: 0,
																	letterSpacing: 0,
																}}
																className={"pc_regular"}
															>
																Send appointment update and notification to this
																phone number
															</H6>
														}
														onChange={(value) =>
															this.changeState("appointmentUpdate", value)
														}
														inLineStyles={{ lineHeight: 1, marginBottom: 16 }}
														className={classes.termsAndConditions}
													/>
													<Div
														id="doctor_listing_guestbookerinfo_mailid_div"
														className={classes.label}
													>
														<TextInput
															id="doctor_listing_guestbookerinfo_mailid_textinput"
															// key={"5"}
															placeholder=""
															type="text"
															name="text"
															variant="outlined"
															helperTextType="error"
															label={
																<H6
																	id="doctor_listing_sidemenu_bookingby_mail_id_span"
																	style={{
																		fontSize: "12px",
																		color: "#6f6f6f",
																		margin: 0,
																		letterSpacing: 0,
																		lineHeight: 1,
																		marginBottom: 8,
																	}}
																>
																	Mail Id
																</H6>
															}
															autoFocus={false}
															autoComplete="off"
															search={false}
															borderColor="#9b9b9b"
															padding="10"
															margin="0"
															elevation="0"
															value={this.state.guestBookerInfo.mailId}
															onChange={(e) =>
																this.changeGuestInfo(
																	"guestBookerInfo",
																	"mailId",
																	e.target.value
																)
															}
															className={classes.textField}
														/>
														{this.state.error?.guestBookerInfo?.mailId && (
															<div id="doctor_listing_guestbookerinfo_mail_error_div">
																<Text
																	id="doctor_listing_guestbookerinfo_mail_error_text"
																	style={{ color: "#FF4D4A", fontSize: 14 }}
																>
																	{"Please Enter Valid Email ID"}
																</Text>
															</div>
														)}
													</Div>

													<TermsAndConditions
														id="doctor_listing_guestbookerinfo_appointment_terms"
														// value={true}
														// key={"6"}
														onChange={(value) => console.log(value)}
														checkboxTickColor={"#fff"}
														activeCheckboxBorderColor={UIColor.secondaryButtonColor.color}
														checkboxBorderColor={UIColor.lineBorderFill.color}
														checkboxBackgroundColor={UIColor.differentiationBackground.color}
														activeCheckboxBackgroundColor={UIColor.secondaryButtonColor.color}
														disabled
														checkboxSize={14}
														lable={
															<H6
																id={`sdoctor_listing_sidemenu_send_termsand_conditions`}
																style={{
																	fontSize: "12px",
																	color: "#6F6F6F",
																	margin: 0,
																	letterSpacing: 0,
																}}
																className={"pc_regular"}
															>
																Send appointment update and notification to this
																mail Id
															</H6>
														}
														inLineStyles={{ lineHeight: 1 }}
														className={classes.termsAndConditions}
													/>

													<Button
														id="doctor_listing_guestbookerinfo_Send_OTP_Confirm_button"
														// key={"7"}
														onClick={() => this.sendOTPandConfirm()}
														type="button"
														variant="outlined"
														name={
															this.props.generateOTP?.loading ||
															this.props.registerUsers?.loading
																? "Sending Otp..."
																: "Send OTP & Confirm"
														}
														disabled={
															this.props.generateOTP?.loading ||
															this.props.registerUsers?.loading
														}
														style={{
															margin: "auto",
															marginTop: "34px",
															backgroundColor: "#0071F2",
															borderColor: "#0071F2",
															borderRadius: "8px",
															display: "flex",
															justifyContent: "center",
															padding: "10px 16px",
															fontSize: "14px",
															height: 40,
														}}
														className="pc_medium"
													></Button>
												</Collapse>
											</Card>
										)}
									</Div>
								)}
							</SideMenu>
						</Col>
					</Row>
					<SideMenu
						id="doctor_listing_rightside_sidemenu"
						open={this.state.bulkBooking}
						direction="right"
						width={585}
						color="default"
						// onClose={() => this.toggleSideBar(false)}
					>
						<Sidemenu1 parent_id={"doctor_listing"} />
					</SideMenu>
				</Container>
				{(this.state?.modalOpen && this.state?.doctorName) &&
					<Dialog
						open={this.state?.modalOpen}
						onClose={this.handleModalClose}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">{"Booking Confirmation?"}</DialogTitle>
						<DialogContent>
						{this.state?.doctorCount > 1 ?
							<DialogContentText id="alert-dialog-description">
							There are <span style={{fontWeight: "bolder", color: "black"}}> {this.state?.doctorCount}</span> appointments for same date time, do you want to continue with this boooking?
							</DialogContentText>
							:
							<DialogContentText id="alert-dialog-description">
							There is an appointment with <span style={{fontWeight: "bolder", color: "black"}}> Dr.{this.state?.doctorName}</span> for same date time, do you want to continue with this boooking?
							</DialogContentText>
						}	
						</DialogContent>
						<DialogActions>
						<Button
							id="patient_detail_put_hold_button"
							style={{
								width: "87px",
								height: "36px",
								border: `1px solid ${UIColor.greyBackground.color}`,
								borderRadius: "8px",
								opacity: 1,
								color: "#323232",
								background: UIColor.greyBackground.color,
								marginRight: "10px",
								marginBottom: "10px",
								cursor: "pointer",
							}}
							onClick={this.handleModalClose}
							>
							{"No"}
							</Button>
							<Button
							id="patient_detail_signoff_button"
							onClick={this.handleBooking}
							style={{
								width: "87px",
								height: "36px",
								border: `1px solid ${UIColor.greyBackground.color}`,
								borderRadius: "8px",
								opacity: 1,
								color: "white",
								background: UIColor.greyBackground.color,
								marginRight: "10px",
								backgroundColor: UIColor.secondaryButtonColor.color,
								borderColor: UIColor.secondaryButtonColor.color,
								marginBottom: "10px",
								cursor: "pointer",
							}}
							>
							{"Yes"}
							</Button>
						{/* <Button onClick={this.handleModalClose} color="primary">
							No
						</Button>
						<Button onClick={this.handleBooking} color="primary" autoFocus>
							Yes
						</Button> */}
						</DialogActions>
					</Dialog>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	docterListing: state?.docterListingReducer?.docterListing,
	docterListingProfile: state?.docterListingReducer?.docterListingProfile,
  allLanguages: state?.docterListingReducer?.allLanguages,
	docterListingBookingBy: state?.docterListingReducer?.docterListingBookingBy,
	registerUsers: state?.authSlice?.registerUsers,
	generateOTP: state?.authSlice?.generateOTP,
	resendOTP: state?.authSlice?.resendOTP,
	signIn: state?.authSlice?.signIn,
	guestInfo: state?.authSlice?.guestInfo,
	registerUsers: state?.authSlice?.registerUsers,
	upsertAppointment: state?.appointmentReducer?.upsertAppointment,
	checkAppointmentAgainstPatient: state?.appointmentReducer?.checkAppointmentAgainstPatient,
	updateAppointment: state?.appointmentReducer?.updateAppointment,
	readPerson: state?.personApiSlice?.readPerson,
	upsertPerson: state?.personApiSlice?.upsertPerson,
	addRelatedPerson: state?.personApiSlice?.addRelatedPerson,
	reasonForAppointment: state?.appointmentReducer?.reasonForAppointMent,
	reasonForApptCancelModify:
		state?.appointmentReducer?.reasonForApptCancelModify,
		CountryCode: state?.authSlice?.country_code_list
});

DoctorListing.contextType = AlertContext;

export default withStyles(styles)(
	connect(mapStateToProps, actions)(withRouter(withAllContexts(DoctorListing)))
);

// export default connect(mapStateToProps, actions)(withRouter(DoctorListing));
