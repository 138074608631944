import {
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { Col, Div, Row, TextInput } from "qdm-component-library";
import React, { useState } from "react";
import {
  PatientCareTypeCard,
  PatientInfoCard,
  TopHeader,
} from "../../components";
import SearchIcon from "@material-ui/icons/Search";
import {
  AlertProps,
  getDateAndTime,
  getUnixCodeDate,
  UIColor,
  utcTOLocal,
} from "../../utils";
import ViewBilling from "./viewBilling";
import BillingSettlement from "./billingSettlement";
import { useStyles } from "./styles";
import EncounterCard from "./encounterCard";
import { Routes } from "../../router/routes";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "primarycare-binder";
import { displayTable, validate } from "./constructJson";
import { withAllContexts } from "../../HOCs";

const steps = ["Patient Order Details", "Generate Bill & Capture Settlement"];

const CashierBillingDetails = (props) => {
  const encounterData = null;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [addNewSwitch, setAddNewSwitch] = useState(false);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [unBilledCheckBox, setUnBilledCheckBox] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [newAddedOrder, setNewlyAdded] = useState([]);
  const [counter, setCounter] = useState(0);
  const [codeSearch, setCodeSearch] = useState("");
  const [standardTableorders, setStandardTableorders] = useState([]);
  const [billNo, setBillNo] = useState({
    billNum: null,
    date: "",
  });
  const [calculationResultData, setCalculationResultData] = useState({
    calculationResult: [],
  });
  const [sharePercent,setSharePercent] = useState({
    discountAmnt: 0,
    patientShare: 100,
  })
  const [payer, setPayer] = useState("");
  console.log("selectedOrders", selectedOrders);
  const [paymentDetails, setPaymentDetails] = useState({
    paymentType: "",
    settleNow: false,
    settleNowValue: "",
    balanceDue: 0.0,
    docRefNo: "",
    docRefDate: 0,
    remarks: "",
    card: "",
    expiry: "",
  });
  const [receipt, setReceipt] = useState({
    receiptNo: null,
    date: "",
  });
  const [roundOffAmnt,setRoundOffAmnt] = useState({
    patientShareFinal:0.00,
    roundOff:0.00
  })
  console.log("paymentDetails", paymentDetails);

  // Handle Payment Details
  const handlePayementDetails = async (e, type) => {
    if (type === "settleNowValue") {
      const balance = calculationResultData?.patientShare - e;
      setPaymentDetails({
        ...paymentDetails,
        [type]: e,
        balanceDue: balance,
        settleNowValue: calculationResultData?.patientShare,
      });
    } else if (type === "docRefDate" || type === "expiry") {
      if(type === "expiry"){
        const mon = e.getMonth();
        const year = e.getFullYear();
        const expiryDate = new Date(new Date(year, mon + 1 , 0).setHours(23, 59, 59, 999));
        const date = new Date(expiryDate) / 1000;
        setPaymentDetails({ ...paymentDetails, [type]: Math.floor(date) });
      }else{
        const date = new Date(e) / 1000;
        setPaymentDetails({ ...paymentDetails, [type]: date });
      }  
    } else {
      setPaymentDetails({ ...paymentDetails, [type]: e });
    }

    if (type === "settleNow" && e === true && receipt?.receiptNo === null) {
      const chequeNo = await dispatch(actions.RECIEPT_NO());
      setReceipt({
        ...receipt,
        receiptNo: chequeNo?.payload?.data?.Result?.receiptno,
        date: getDateAndTime(new Date(new Date()) / 1000),
      });
    }
  };

  const onIncrement = async () => {
    const currentCount = counter;
    if (props?.location?.state?.data?.encounter.length - 1 > currentCount) {
      const setCount = currentCount + 1;
      const patientId = props?.location?.state?.data?._id;
      const encounterId =
        props?.location?.state?.data?.encounter[setCount]?._id;
      setCounter(setCount);
      const data = await dispatch(
        actions.TABLE_DATA({ patientId, encounterId })
      );
      const tableData = displayTable(data?.payload?.data?.result ?? []);
      setStandardTableorders(tableData);
      setDisplayOrders(tableData);
    }
  };

  const onDecrement = async () => {
    const currentCount = counter;
    if (currentCount > 0) {
      const setCount = currentCount - 1;
      const patientId = props?.location?.state?.data?._id;
      const encounterId =
        props?.location?.state?.data?.encounter[setCount]?._id;
      setCounter(setCount);
      const data = await dispatch(
        actions.TABLE_DATA({ patientId, encounterId })
      );
      const tableData = displayTable(data?.payload?.data?.result ?? []);
      setStandardTableorders(tableData);
      setDisplayOrders(tableData);
    }
  };

  React.useEffect(() => {
    debugger;
    const newSelectedOrders = displayOrders.filter(
      (val, i) => val?.checked === true
    );
    if (newAddedOrder.length > 0) {
      newAddedOrder.map((val, i) => newSelectedOrders.push(val));
      setSelectedOrders(newSelectedOrders);
    }else if(codeSearch.length > 0 && newSelectedOrders?.length > 0){
      setSelectedOrders(newSelectedOrders);
    } else if (codeSearch.length > 0) {
      debugger;
      console.log("nothing is happened");
    } else {
      setSelectedOrders(newSelectedOrders);
    }
  }, [displayOrders]);

  React.useEffect(() => {
    getTableData();
  }, []);

  // initail Table Date
  const getTableData = async () => {
    const patientId = props?.location?.state?.data?._id;
    const encounterId = props?.location?.state?.data?.encounter[counter]?._id;
    const data = await dispatch(actions.TABLE_DATA({ patientId, encounterId }));
    const tableData = displayTable(data?.payload?.data?.result ?? []);
    setDisplayOrders(tableData);
    setStandardTableorders(tableData);
  };

  const setTheTableData = useSelector(
    (state) => state?.billSettlementSlice?.table_data_read
  );

  // Handling the unbilled charges checkBox
  const handleUnBilled = (e) => {
    setUnBilledCheckBox(e.target.checked);
    const unBilledOrders = displayOrders.filter(
      (val, i) => val.billed === false
    );
    const standardUnBilled = standardTableorders.filter(
      (val, i) => val.billed === false
    );
    if (!e.target.checked) {
      let displayTableData = displayTable(setTheTableData?.data?.result ?? []);
      setDisplayOrders(displayTableData);
      setStandardTableorders(displayTableData);
    } else {
      setDisplayOrders(unBilledOrders);
      setStandardTableorders(standardUnBilled);
    }
  };

  const [roundOffval,setRoundOff] = useState(0.00);
  // Proceed Button
  const handleProceed = async () => {
    if (activeStep !== steps.length - 1 && selectedOrders.length > 0) {
      setActiveStep(activeStep + 1);
      const orgId = localStorage.getItem("nameOfOrg")
      const payerDropDwn = await dispatch(actions.PAYER_DROPDOWN());
      const chargeDropDwn = await dispatch(actions.CHARGE_CODE_DROPDOWN());
      const uomDropDown = await dispatch(actions.UOM_DROPDOWN());
      const paymentDropDown = await dispatch(actions.PAYMENT_DROPDOWN());
      const roundOffValue = await dispatch(actions.BILL_ROUND_OFF({orgId}));
      setRoundOff(roundOffValue?.payload?.data?.result?.[0]?.parametervalue)
      if (billNo?.billNum === null) {
        const billNom = await dispatch(actions.BILL_NO());
        setBillNo({
          ...billNo,
          billNum: billNom?.payload?.data?.Result?.billno ?? null,
          date: getDateAndTime(new Date(new Date()) / 1000),
        });
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "Please select atleast one order",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  // Previous Button
  const handlePrevious = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
      setAddNewSwitch(false);
      const oldSelectedOrder = selectedOrders.filter(
        (val, i) => val?.isNew === undefined
      );
      const isChecked = oldSelectedOrder.filter(
        (val, i) => val?.checked === true
      );
      const unBilledDisplayOrder = displayOrders.filter(
        (val, i) => val?.billed === false
      );
      if (isChecked.length === unBilledDisplayOrder.length) {
        setAllChecked(true);
      } else {
        setAllChecked(false);
      }
    }
  };

  // Add new Button
  const handleAddnew = () => {
    setAddNewSwitch(true);
  };

  // Add New order
  const handleAddNewOrder = (data) => {
    const addNewOrder = JSON.parse(JSON.stringify(selectedOrders));
    addNewOrder.push(data);
    const newlyAdded = addNewOrder.filter((val, i) => val?.isNew === true);
    setNewlyAdded(newlyAdded);
    setSelectedOrders(addNewOrder);
    setAddNewSwitch(false);
  };

  // Close New Order Bar
  const handleRemoveAddnew = () => {
    setAddNewSwitch(false);
  };

  // handle Check Box
  const handleCheckBox = (e, row, rowIndex) => {
    let newOrders = JSON.parse(JSON.stringify(displayOrders));
    newOrders[rowIndex].checked = e.target.checked;
    let standardTableOrdersData = JSON.parse(
      JSON.stringify(standardTableorders)
    );
    const standardIndex = standardTableOrdersData.findIndex(
      (v) => v?.id === row?.id
    );
    standardTableOrdersData[standardIndex].checked = e.target.checked;
    setDisplayOrders(newOrders);
    setStandardTableorders(standardTableOrdersData);
  };

  // calculated selectedOrders
  const handleCalculations = (results, rowIndex) => {
    if (
      typeof results === "object" &&
      !Array.isArray(results) &&
      results !== null
    ) {
      const editedText = JSON.parse(JSON.stringify(selectedOrders));
      editedText.splice(rowIndex, 1, results);
      setSelectedOrders(editedText);
    } else {
      setSelectedOrders(results);
    }
  };

  // select all unbilled orders
  const handleHeaderCheckBox = (e) => {
    let newOrders = JSON.parse(JSON.stringify(displayOrders));
    newOrders.map((val, i) => {
      if (val.billed === false) {
        return (val.checked = e.target.checked);
      } else {
        return (val.checked = false);
      }
    });
    setAllChecked(e.target.checked);
    setDisplayOrders(newOrders);
    setStandardTableorders(newOrders);
  };

  // deleting the selected orders
  const handleDeleteSelected = (row, rowIndex) => {
    const selectedOrder = displayOrders?.filter((val, i) => val.id === row.id);
    const selectedIndex = displayOrders?.indexOf(selectedOrder[0]);
    if (selectedIndex > -1) {
      let newOrders = JSON.parse(JSON.stringify(displayOrders));
      newOrders[selectedIndex].checked = false;
      setDisplayOrders(newOrders);
      setStandardTableorders(newOrders);
    } else {
      const newOrder = JSON.parse(JSON.stringify(selectedOrders));
      newOrder.splice(rowIndex, 1);
      setSelectedOrders(newOrder);
    }
  };

  // Onchange of the discount and the patient Share
  const handleTableTextChange = (e, row, rowIndex, type) => {
    debugger;
    const isNumber = Number.isNaN(row?.creditShare);
    const discounted = Number.isNaN(row?.dicount);
    const totalCounted = Number.isNaN(row?.patientShare);
    const total =
      (discounted ? 0 : row?.dicount) + (totalCounted ? 0 : row?.patientShare);
    const patientShareValue = () => {
      if (
        Number(e.target.value) > Number(row?.net) &&
        type === "patientShare"
      ) {
        return false;
      } else if (
        type === "dicount" &&
        (Number(row?.gross) <= Number(row?.patientShare))  
      ) {
        return true;
      } else if (
        type === "dicount" &&
        Number(row?.gross) <= Number(e.target.value)
      ) {
        return false;
      } else {
        return true;
      }
    };
    if (
      e.target.value >= 0 &&
      e.target.value !== "-" &&
      total <= Number(row?.gross) &&
      patientShareValue()
    ) {
      if(type === "dicount"){
        const editOrders = JSON.parse(JSON.stringify(selectedOrders));
        editOrders[rowIndex][type] = parseFloat(e.target.value);
        editOrders[rowIndex]["patientShare"] = e.target.value > 0 ? ((row?.gross - e.target.value)/100) * sharePercent?.patientShare : row?.gross - e.target.value;
        editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };
        setSelectedOrders(editOrders);
      }else{
        const editOrders = JSON.parse(JSON.stringify(selectedOrders));
        editOrders[rowIndex][type] = parseFloat(e.target.value);
        editOrders[rowIndex] = { ...editOrders[rowIndex], isEdited: true };
        setSelectedOrders(editOrders);
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: "The credit share cant be a negative value",
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  // The Billing component Switch
  const getStepperComponents = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <ViewBilling
            displayOrders={displayOrders}
            handleCheckBox={handleCheckBox}
            handleHeaderCheckBox={handleHeaderCheckBox}
            allChecked={allChecked}
            codeSearch={codeSearch}
            standardTableorders={standardTableorders}
          />
        );
      case 1:
        return (
          <BillingSettlement
            addNewSwitch={addNewSwitch}
            setSharePercent={setSharePercent}
            sharePercent={sharePercent}
            handleRemoveAddnew={handleRemoveAddnew}
            selectedOrders={selectedOrders}
            handleCalculations={handleCalculations}
            handleAddNewOrder={handleAddNewOrder}
            handleDeleteSelected={handleDeleteSelected}
            handleTableTextChange={handleTableTextChange}
            billNo={billNo}
            setPaymentDetails={setPaymentDetails}
            setCalculationResultData={setCalculationResultData}
            calculationResultData={calculationResultData}
            handlePayer={handlePayer}
            payer={payer}
            handlePayementDetails={handlePayementDetails}
            paymentDetails={paymentDetails}
            receipt={receipt}
            roundOffval={roundOffval}
            setRoundOffAmnt = {setRoundOffAmnt}
            roundOffAmnt={roundOffAmnt}
          />
        );
      default:
        return "Unknown step";
    }
  };

  // cancel the billing Process
  const handleCancel = () => {
    props.history.push({
      pathname: Routes.dashboard,
    });
  };

  // Search code and description in the display table
  const handleCodeSearch = async (value) => {
    const patientId = props?.location?.state?.data?._id;
    const encounterId = props?.location?.state?.data?.encounter[counter]?._id;
    setCodeSearch(value);
    const data = await dispatch(
      actions.SEARCH_CODE_DESC({ patientId, encounterId, value })
    );
    const constructedData = displayTable(data?.payload?.data?.result);
    const newData = constructedData.map(
      (val) => standardTableorders.find((v) => v.id === val.id) || val
    );
    setDisplayOrders(newData);
  };

  //handle Payer
  const handlePayer = (e) => {
    setPayer(e);
  };

  const handleSave = async () => {
    const billHeaderSave = {
      billno: billNo?.billNum ?? "",
      billdate: Math.floor(new Date(new Date()) / 1000),
      patientid: props?.location?.state?.data?._id,
      encounterid: props?.location?.state?.data?.encounter[counter]?._id,
      totgrossamt: calculationResultData?.gross,
      totdiscamt: calculationResultData?.dicount,
      totnetamt: calculationResultData?.net,
      totpatientshare: (calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)).toFixed(2),
      totpayershare: calculationResultData?.creditShare,
      billhstatus: true,
      payerid: payer?.label,
    };
    const validation = validate(billHeaderSave, paymentDetails);
    console.log("validation",validation);
    debugger;
    if (validation?.status) {
      const billHeaderResult = await dispatch(
        actions.BILL_HEADER_SAVE({ billHeaderSave })
      );
      const billHeaderId =
        billHeaderResult?.payload?.data?.Result[0]?.properties?.doc?._id;
      if (billHeaderId) {
        const billingDetails = selectedOrders?.map((val, i) => {
          if (typeof val === "object" && !Array.isArray(val) && val !== null) {
            const billrefno = {
              billrefno: billHeaderId,
            };
            return { ...val, ...billrefno };
          } else {
            return val;
          }
        });
        const billDetailsSave = billingDetails.map((val) => {
          return {
            dispenseid: val?.orderdispenseid,
            patientid: props?.location?.state?.data?._id,
            encounterid: props?.location?.state?.data?.encounter[counter]?._id,
            billrefno: billHeaderId,
            patchargetrxid: val?.id,
            orderrefid: val?.orderrefid,
            grossamt: Number(val?.gross),
            discamt: Number(val?.dicount),
            netamt: Number(val?.net),
            patientshare: Number(val?.patientShare),
            payershare: Number(val?.creditShare),
            billdstatus: true,
            chargecodeid: val?.chargecodeid,
            billqty: parseInt(val?.qty),
            billuom: val?.uomId,
            billrate: val?.rate,
          };
        });
        const billDetailsResult = await dispatch(
          actions.BILL_DETAILS_SAVE({ billDetailsSave })
        );
        const paymentPayload = {
          patientid: props?.location?.state?.data?._id,
          encounterid: props?.location?.state?.data?.encounter[counter]?._id,
          billhdrid: billHeaderId,
          receiptno: receipt?.receiptNo,
          receiptdate: getUnixCodeDate(receipt?.date),
          billamt: (calculationResultData?.patientShare + Number(roundOffAmnt?.roundOff)).toFixed(2),
          paidamt:(Number(paymentDetails?.settleNowValue) + Number(roundOffAmnt?.roundOff)).toFixed(2),
          balamt: paymentDetails?.balanceDue,
          roundoffamt: roundOffAmnt?.roundOff,
          paymentdtls: [
            {
              paymentmode: paymentDetails?.paymentType?.label,
              docrefno: paymentDetails?.docRefNo,
              docrefdate: paymentDetails?.docRefDate,
              remarks: paymentDetails?.remarks,
              cardno:Number(paymentDetails?.card),
              expdate: paymentDetails?.expiry?.length === 0 ? 0 : paymentDetails?.expiry
            },
          ],
          settlenow: paymentDetails?.settleNow,
        };
        const paymentResult = await dispatch(
          actions.PAYMENT_SAVE({ paymentPayload })
        );
        const billIds = selectedOrders.map((val, i) => {
          return val?.id;
        });
        const billStatusResult = await dispatch(
          actions.BILL_STATUS_SAVE({ billIds })
        );
        debugger;
        const demoRecieptDetails = await dispatch(
          actions.BILL_RECEIPT_READ({ billHeaderId })
        );
        console.log("demoRecieptDetails", demoRecieptDetails);
        props.history.push({
          pathname: Routes.billingSummary,
          state: {
            encounterNo: `EN - ${props?.location?.state?.data?.encounter[counter]?._key}`,
            demoRecieptDetails : demoRecieptDetails
          },
        });
        // setActiveStep(activeStep - 1);
        // getTableData();
      }
    } else {
      props.alert.setSnack({
        open: true,
        severity: AlertProps.severity.error,
        msg: validation?.msg,
        vertical: AlertProps.vertical.top,
        horizontal: AlertProps.horizontal.right,
      });
    }
  };

  const patientCardDetails = props?.location?.state?.data;
  const encounterDetails = props?.location?.state?.data?.encounter;
  console.log(
    "props?.location?.state?.data?.encounter",
    `EN - ${props?.location?.state?.data?.encounter[counter]?._key}`
  );
  return (
    <div style={{ backgroundColor: "#F4F5F8", paddingBottom: "1px" }}>
      <TopHeader
        parent_id={"cashierBilling"}
        noHome={true}
        middleComponent={<></>}
      />

      {/* ------------------------------------------------------ Top Layer Cards ----------------------------------------------------- */}
      <Row
        id="patient_detail_parent_row"
        inLineStyles={{
          // background:
          // 	"transparent linear-gradient(90deg, rgb(251 194 235 / 20%) 0%, hsl(237deg 78% 82% / 20%) 49%, #6bbbff33 100%) 0% 0% no-repeat padding-box",
          while: "white",
          padding: "5px 10px 5px 10px",
          borderBottom: "1px solid #DEE5EC",
          backgroundColor: "#fff",
        }}
      >
        <Col
          id="patient_detail_parent_col"
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
        >
          <PatientInfoCard
            parent_id={"patient_detail_billing"}
            pic={patientCardDetails?.img ?? "images/Group 90506.svg"}
            name={patientCardDetails?.name ?? "--------------"}
            age={patientCardDetails?.age ?? "-"}
            gender={patientCardDetails?.gender ?? "-"}
            mrn_id={patientCardDetails?.id ?? "MRN_-----"}
            pi_id={"PI " + patientCardDetails?.identifier ?? "PI------"}
          />
        </Col>

        <Col
          id="patient_detail_statuscard_col"
          xl={5}
          lg={5}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5, borderRight: "1px solid #DFE5EB" }}
        >
          <EncounterCard
            encounterDetails={encounterDetails}
            counter={counter}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
            parent_id={"patient_detail"}
            pic={
              "images/Group 90506.svg"
              // "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg"
            }
            titles={[
              encounterData?.id ?? "65892",
              "OP",
              encounterData?.type ?? "type",
              encounterData?.speciality ?? "Cardiology",
            ]}
            date={
              encounterData
                ? utcTOLocal(encounterData?.encounterdate, "DD MMM YYYY")
                : "01 Jan 22 10:10 - 01 Jan 22 23:00"
            }
            languages={encounterData?.lang ?? "lang"}
          />
        </Col>
        <Col
          id="patient_detail_care_typecard_col"
          xl={3}
          lg={3}
          md={6}
          sm={12}
          xs={12}
          inLineStyles={{ padding: 5 }}
        >
          <PatientCareTypeCard
            parent_id={"patient_detail"}
            // careList={this.state.fin_data}
            // selected={this.state.selectOption}
            // onChange={(data) => this.changeState("selectOption", data)}
          />
        </Col>
      </Row>
      {/* ---------------------------------------------------------------------------------------------------------------------------- */}

      <div className={classes.contents}>
        <Grid
          container
          style={{
            borderBottom: "1px solid #DFE5EB",
            paddingRight: "20px",
            alignItems: "center",
          }}
        >
          <Grid item xs={6}>
            <Grid container>
              <Grid item xs={2} style={{ padding: "10px 10px 10px 22px" }}>
                <Typography className={classes.header}>Billing</Typography>
              </Grid>
              <Grid item xs={10}>
                <Stepper
                  className={classes.stepper}
                  activeStep={activeStep}
                  style={{ padding: "10px 0px 10px 8px" }}
                >
                  {steps.map((val, i) => (
                    <Step key={i}>
                      <StepLabel>{val}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              {activeStep === 0 ? (
                <>
                  <Grid
                    item
                    xs={6}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <FormControlLabel
                      style={{ margin: "0px 16px 0px 0px" }}
                      control={
                        <Checkbox
                          checked={unBilledCheckBox}
                          color="primary"
                          size="small"
                          onChange={(e) => handleUnBilled(e)}
                        />
                      }
                      label={
                        <Typography className={classes.labelStyle}>
                          Unbilled charges Only?
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Div
                      id="sidemenu_search_input_sub_div"
                      style={{ display: "flex", position: "relative" }}
                    >
                      <TextInput
                        id="sidemenu_search_input_textinput"
                        value={codeSearch}
                        onChange={(evt) => {
                          handleCodeSearch(evt.target.value);
                        }}
                        style={{
                          margin: "6px 16px 0px 0px",
                          borderRadius: 8,
                          borderColor: UIColor.lineBorderFill.color,
                          padding: "0px 10px",
                          height: 30,
                        }}
                        // search
                        variant={"outlined"}
                        // id={"inputId"}
                        label=""
                        placeholder="Search Code & Description"
                        hoverColor="#0071F2"
                      />
                      <SearchIcon
                        id="sidemenu_search_searchicon"
                        className={classes.searchIcon}
                      />
                    </Div>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} className={classes.addNewColumn}>
                  <Button
                    onClick={handleAddnew}
                    variant="contained"
                    style={{
                      backgroundColor: "#EC6A49",
                      textTransform: "none",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    + Add New
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div style={{ overflowY: "auto", height: "87%", overflowX: "hidden" }}>
          {/*----------------------------------- Loading the Tables----------------------------------- */}
          {getStepperComponents(activeStep)}
          {/* ---------------------------------------------------------------------------------------------- */}
        </div>
      </div>
      <div className={classes.buttonAlign} style={{justifyContent: activeStep !== 0 ? "space-between" : "end" }}>
        {activeStep !== 0 && (
          <>
            <Typography className={classes.calculationLabel}>
              Remarks :
            </Typography>
            <TextField
              id="standard-full-width"
              fullWidth
              margin="normal"
              onChange={(e) => handlePayementDetails(e.target.value, "remarks")}
              InputProps={{
                classes: {
                  input: classes.resize,
                },
              }}
              className={classes.remarksTextField}
              inputProps={{ maxLength: 200 }}
              value={props?.paymentDetails?.remarks}
              size={"small"}
              variant={"outlined"}
            />
          </>
        )}
        <div style={{display:"flex", gap:10}}>
          <Button
            style={{ color: "#6A7888" }}
            onClick={handleCancel}
            className={classes.forButton}
          >
            Cancel
          </Button>
          {activeStep !== 0 && (
            <>
              <Button
                variant="contained"
                style={{ backgroundColor: "#DEE5EC", color: "#2A3C50" }}
                onClick={handlePrevious}
                className={classes.forButton}
              >
                Previous
              </Button>
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={activeStep === 0 ? handleProceed : handleSave}
            className={classes.forButton}
          >
            {activeStep === 0 ? "Proceed for billing" : "Save"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withAllContexts(CashierBillingDetails);
